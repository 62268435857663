.cases {
    display: none;
    background: white;
}

@media(min-width:1024px) {
    .slide_item {
        display: grid;
        grid-template-columns: 450px 1fr;
        width: 100%;
        max-width: 700px; 
        border-radius: 32px;
        border: 2px solid #24359c;
        overflow: hidden;

        .slide-item-text {
            display: flex;
            flex-direction: column;
            padding: 53px 20px 29px 52px;
            justify-content: space-between;

            .partner_logo {
                width: 100%;
                max-width: 170px;
            }

            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: var(--gray2);
                margin-top: 26px;
                padding-bottom: 0;
            }

            a {
                align-self: flex-start;
                padding: 12px 20px;
                color: white;
                text-transform: uppercase;
                text-decoration: none;
                background: #21232f;
                border-radius: 39px;
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;
                display: block;
                white-space: nowrap;
                margin-top: 21px;
            }
        }

        .image {
            display: flex;
            width: 100%;
            max-width: 378px;

            img {
                object-fit: cover;
                width: 100%;
            }
        }
    }
}