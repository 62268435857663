.terms-of-use{
    .grid-column-2{
        grid-column: 2/3;
        a{
            color: var(--pink);
        }
        h3{
            margin-top: 30px;
        }
        h4{
            margin-top: 40px;
            font-size: 32px;
            font-weight: bold;
        }
    }
}