.main-how-to-page {
    background: url("../../src/../src/images/image-bg-green-arrow.png"),
      url("../../src/../src/images/image-bg-green-arrow.png"),
      url("../../src/../src/images/background/background-how-to-page.png");
    background-repeat: no-repeat;
    background-size: 250px 400px, 250px 400px, 100% 57%;
    background-position: -60px 400px, -60px 1170px, 0px 320px;
  }
  
  .main-how-to-page div {
    padding-left: 44px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
  }
  
  .main-how-to-page div h2 {
    color: #333;
    font-family: var(--font-text);
    max-width: 270px;
    padding: 0px 44px;
  
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin-top: 16px;
  }
  
  .main-how-to-page div h5 {
    color: #fff;
    font-family: var(--font-text);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin-top: 16px;
  }
  
  .main-how-to-page div p {
    font-size: 16px;
    line-height: 28px;
    max-width: 280px;
    margin-top: 16px;
  }
  
  .main-how-to-page .installment__image {
    width: fit-content;
    position: relative;
    max-width: 600px;
  }
  
  .main-how-to-page .sh-1 .installment__image {
    left: -50px;
  }
  
  .main-how-to-page .sh-1 .installment__texts h5 {
    color: #333;
  }
  
  .main-how-to-page .sh-2 {
    flex-direction: column-reverse;
    color: white;
  }
  
  .main-how-to-page .sh-2 .installment__texts h2 {
    color: white;
  }
  
  .main-how-to-page .sh-3 .installment__texts h5 {
    color: #333;
  }
  
  .main-how-to-page .sh-3 .installment__image {
    left: -80px;
    max-width: 550px;
  }
  
  @media (min-width: 768px) {
    .main-how-to-page div h2 {
      font-size: 48px;
      line-height: 53px;
      font-weight: 600;
      max-width: 430px;
      margin-top: 64px;
    }
  
    .main-how-to-page div h5 {
      font-size: 22px;
      line-height: 28px;
      font-weight: 600;
      max-width: 240px;
      margin-top: 16px;
    }
  
    .main-how-to-page div p {
      font-size: 16px;
      line-height: 28px;
      max-width: 370px;
      margin-top: 16px;
    }
  }
  
  @media (min-width: 1024px) {
    .main-how-to-page {
      background: url("../../src/images/image-bg-green-arrow.png"),
        url("../../src/images/image-background-pink-how.png");
      background-repeat: no-repeat;
      background-size: 500px 800px, 160% 85%;
      background-position: -100px 300px, -200px -20px;
  
      display: grid;
      grid-template-columns: auto 900px auto;
    }
  
    .main-how-to-page div {
      grid-column: 2/3;
      padding: 0;
      padding-left: 40px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      margin-top: 0;
      align-items: flex-start;
    }
  
    .main-how-to-page div h2 {
      font-size: 48px;
      line-height: 53px;
      font-weight: 600;
      max-width: 430px;
      margin-top: 64px;
      padding: 0;
    }
  
    .main-how-to-page div h5 {
      font-size: 22px;
      line-height: 28px;
      font-weight: 600;
      max-width: 240px;
      padding: 5px 0;
    }
  
    .main-how-to-page div p {
      font-size: 16px;
      line-height: 28px;
      max-width: 370px;
    }
  
    .main-how-to-page .installment__image {
      height: inherit;
      max-height: 530px;
      object-fit: contain;
    }
  
    .main-how-to-page .sh-1 .installment__image {
      top: -380px;
      left: -20px;
      align-self: flex-end;
    }
  
    .main-how-to-page .sh-2 {
      flex-direction: row;
      position: relative;
      top: -40px;
    }
  
    .main-how-to-page .sh-3 .installment__texts h2 {
      margin-top: 0;
    }
  
    .main-how-to-page .sh-3 {
      flex-direction: row;
      width: 100%;
      position: relative;
      top: 50px;
    }
  
    .main-how-to-page .sh-3 .installment__image {
      position: relative;
      left: unset;
      right: 0;
      top: -50px;
      align-self: flex-end;
    }
  }
  
  @media (min-width: 1280px) {
    main.main-how-to-page {
      grid-template-columns: auto 1060px auto;
    }
  }
  @media(max-width:978px){
    .installment__image{
      max-width: 100% !important;
      height: auto;
      left: unset !important;
    }
    .main-how-to-page div{
      padding: 0 20px;
      h2{
        padding-left: 0 !important;
        max-width: unset;
      }
      p{
        max-width: unset;
      }
      h5{
        max-width: unset;
      }
    }
  }