html {
    scroll-behavior: smooth;
}
.mini-stores__container{
    padding: 60px 0 100px;
    
    .mini-stores__heading{
        grid-column-start: 2;
        h2{
            font-size: 48px;
            font-weight: 600;
            img{
                margin-right: 10px;
            }
        }
        h3{
            font-size: 22px;
            font-weight: 600;
            margin:16px 0 24px;
        }
        .mini-stores__filter{
            gap: 3px;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 15px;
            border-bottom: 1.5px solid rgba(194, 194, 194, 0.8);
            a{
                font-weight: 600;
                color: #fe35f1;
                font-size: 20px;
                text-decoration: none;
                padding: 0px 10px;
                min-width: 28px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 28px;                
                &:hover, &.active{
                    background-color: #000;
                    border-radius: 5px;
                    transition: .1s;
                    color: #fff;
                }
                &.disabled{
                    pointer-events: none;
                }
            }
        }
    }
    .mini-stores__block {
        grid-column-start: 2;
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(5, 215px);
        grid-template-rows: auto;
        gap: 20px 22px;
        @media(max-width:978px){
            grid-template-columns: unset !important;
            display: flex;
        }
        a{
            color: #000;
            font-weight: 600;
            font-size: 18px;
            text-decoration: none;
            position: relative;
            padding: 6px 40px 6px 10px;
                &:hover{
                    background-color: #000;
                    border-radius: 5px;
                    transition: .1s;
                    color: #fff;

                    &::before{
                        content: url(../../src/images/arrows.svg);
                        transform: scale(0.1);
                        max-height: 20px;
                        right: -100px;
                        bottom: 20px;
                        position: absolute;
                    }    
                }
                @media(max-width:978px){
                    padding: 6px 6px 6px 10px;
                }
        }
        .skeleton {
            background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
            background-size: 200% 100%;
            animation: loading 1.5s infinite;
            height: 16px;
            width: 100%;
        }            
        @keyframes loading {
            0% {
                background-position: 200% 0;
            }
            100% {
                background-position: -200% 0;
            }
        }

        .small {
            display: grid;
            grid-template-columns: repeat(5, 215px);
            grid-template-rows: auto;
            gap: 20px 22px;
            @media(max-width:978px){
                gap: 7px 24px;
            }
        }
    }
}
#filtered-posts-content-micro, #filtered-posts-content-map{
    .mini__stores-search{
        width: 100%;
        grid-column: 1/5;
        
        h2{
            grid-column-start: 2;
            font-size: 22px;
            font-weight: 600;
            margin: 20px 0;
        }
        
        .mini__stores-return{
            grid-column-start: 2;
            padding-top: 20px;
            display: grid;
            grid-template-columns: repeat(5, 208px);
            grid-template-rows: auto;
            gap: 20px 22px;
            @media(max-width:1200px){
                grid-template-columns: repeat(4, 145px);
            }
            @media(max-width:978px){
                grid-template-columns: repeat(2, 145px);
            }
            a{
                color: #000;
                font-weight: 600;
                font-size: 18px;
                text-decoration: none;
                position: relative;
                transition: .1s;
                padding: 6px 40px 6px 10px;
                &:hover{
                    background-color: #000;
                    border-radius: 5px;
                    transition: .1s;
                    color: #fff;

                    &::before{
                        content: url(../../src/images/arrows.svg);
                        transform: scale(0.1);
                        max-height: 20px;
                        right: -100px;
                        bottom: 20px;
                        position: absolute;
                    }    
                }
                
            }
        }
    }
}
.mini-border-top {
    border-top: 1.5px solid rgba(194, 194, 194, 0.8);
    padding-top: 20px;
}
@media(max-width:978px){
    .mini-stores__container{
        padding: 60px 20px 100px;
    }
    .mini-stores__container .mini-stores__block .small{
        grid-template-columns: repeat(2, 46%);
        width: 100%;
        grid-column: 1/2;
    }
    .skeleton {
        width: 60% !important;
    }
    .mini-stores__filter {
        display: flex;
        flex-wrap: wrap;
        line-height: 1rem;
        a {
            padding: 0.4rem !important;
        }
    }
}