.tutorial-carousel {
  .c-steps {
    z-index: 999;
    display: flex;
    gap: 40px;

    .slide-pass {
      color: var(--gray);
      font-weight: 700;
      font-family: var(--font-text);
      cursor: pointer;
      transition: .2s;

      &.active {
        color: var(--pink);
      }

      &:hover {
        color: var(--pink);
      }
    }

    .slick-current {
      cursor: pointer;

      .slide-pass {
        &:hover {
          color: var(--pink);
        }
      }
    }
  }

  .tutorial-carousel-items {

    position: relative;

    // &::before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: 1;
    //   background: rgb(255, 255, 255);
    //   background: linear-gradient(73deg, rgba(255, 255, 255, 1) 95%, rgba(0, 212, 255, 0) 100%);
    //   height: 100%;
    //   width: 450px;
    // }
  }

  h2 {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 2;
  }
  
  .c-steps {
    position: absolute;
    left: 0;
    top: 130px;
    z-index: 2;
  }

  .item-infos {
    padding-top: 200px;
    max-width: 430px;
    z-index: 2;
  }



  background: url("../../src/images/arrow-carousel.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  // height: 700px;
  justify-content: center;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
}

.tutorial-carousel-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tutorial {
  background: none;
  display: block;
  flex-direction: row;
  padding-bottom: 0;
  margin-top: 0;
}

.c-slide__item {
  display: grid !important;
  grid-template-columns: 600px 1fr;
  align-content: center;
  position: relative;


}

.c-slide__item .item-infos h2 {
  font-size: 48px;
  max-width: 400px;
  line-height: 53px;
  z-index: 2;
}

.c-slide__item .item-infos h4 {
  font-size: 24px !important;
  margin-top: 26px;
  font-weight: 600;
  line-height: 28px;
  width: 100%;
  max-width: 320px;
  z-index: 2;
}

.c-slide__item .item-infos .c-steps {
  display: flex;
  gap: 40px;
  color: var(--gray);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.56px;
  padding: 21px 0;
}

.c-slide__item .item-infos p {
  margin-top: 8px;
  color: #21232f;
  font-size: 16px;
  line-height: 28px;
  z-index: 2;
}

.c-slide__item .item-image img {
  max-width: 520px;
  object-fit: contain;
  z-index: 1;
}

.tutorial .tutorial__smartphones {
  display: none;
}

.tutorial__title-1 {
  display: none;
}

.tutorial__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;
}
@media(max-width:1024px){
  .tutorial__container{
    padding: 60px 20px;
  }
}