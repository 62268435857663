.title {
  font-family: $fontText;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: $black;
}

.where-buy {
  padding: 19px 57px;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  background: #21232f;
  border-radius: 39px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  position: absolute;
  margin-bottom: 56px;
  bottom: 0px;

  &:hover {
    color: #fff;
  }
}

.showcase-nav {
  @media(max-width:978px) {
    display: none;
  }

  display: grid;
  grid-template-columns: 1fr 1060px 1fr;
  position: relative;

  .nav-wrapper {
    grid-column-start: 2;
    left: 320px;
    position: absolute;
    bottom: 65px;

  }

  .dots-navigation {
    display: flex !important;
    position: absolute;
    left: 13.5px;
  bottom: 6.5px;
    z-index: 99;

    ul {
      display: flex;
      position: absolute;
      justify-content: space-around;
      max-width: 150px;
      width: 150px;

      li {
        button {
          display: none;
        }

        &::marker {
          content: url("../../src/images/not-selected-gray.svg");
        }
      }

      .slick-active {
        &::marker {
          content: url("../../src/images/selected-gray.svg");
        }
      }
    }
  }

  .arrows-navigation {
    width: 200px;
    display: flex;
    justify-content: space-between !important;
  }
}





.text {
  font-family: $fontText;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;

  color: $dark1;
}

.showcase__container {
  position: relative;

  .slide-navigation-category,
  .slide-navigation-comment,
  .slide-navigation-stores {
    position: relative;
    margin-top: 46px;
  }

  .dots-navigation,
  .arrows-navigation {
    display: flex;
    justify-content: center;
  }

  .arrow {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 0;
  }

  .arrow.disabled {
    cursor: initial;
  }

  .arrow.disabled svg path {
    stroke: #cccdce;
  }

  .prevArrow {
    transform: rotate(180deg);
    left: 0;
  }

  .nextArrow {
    right: 0px;
  }

  .dots-navigator {
    display: flex !important;
    justify-content: space-around;
    width: 40%;
  }

  .dots-navigatorul {
    display: flex;
    justify-content: space-around;
  }

  .dots-navigator li button {
    display: none;
  }

  .dots-navigator li::marker {
    content: url("../../src/images/not-selected-gray.svg");
  }

  .dots-navigator li.slick-active::marker {
    content: url("../../src/images/selected-gray.svg");
  }

  .dots-class {
    position: absolute;
    top: 38%;
    right: 0;
    padding-right: 23px;
  }

  .dots-class li button {
    display: none;
  }

  .slider .dots-class li::marker {
    content: url("../../src/images/not-selected-pink.svg");
  }

  .dots-class li.slick-active::marker {
    content: url("../../src/images/selected-pink.svg");
  }

  .slider.slick-initialized .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider .slick-slide img {
    width: 100%;
  }

  main {
    width: 100%;
    transition: all 0.2s linear;
    filter: blur(0px);
    padding: 53px 0px 0px;
    background: #f9fafb;
    overflow-x: hidden;
  }

  section {
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  .pagaleve-news {
    display: none;
  }

  .background-layer-image {
    display: none;
  }

  .buying-content {
    margin-top: 53px;
    padding: 0 27px;
    margin-bottom: 70px;
  }

  .buying-content h2 {
    text-align: center;
    padding: 0 10px;
  }

  .buying-content__search {
    position: relative;
    width: 100%;
    max-width: 600px;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    margin: 27px auto 0;
    box-sizing: border-box;
  }

  .buying-content__search input {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 22px 26px;
    border: none;
    border-radius: 100px;
    font-family: $fontText;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #888888;
    box-sizing: border-box;
  }

  .buying-content__search input:focus-visible {
    outline: 2px solid transparent;
  }

  .buying-content__search img {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 85px;
  }

  .choose_category {
    display: none;
  }

  .featured-stores {
    width: 100%;
    padding: 0px 43px;
    box-sizing: border-box;
    position: relative;
  }

  .featured-stores__title {
    width: 100%;
    width: 187px;
  }

  .all_stores {
    display: none;
  }

  .featured-stores__container {
    position: relative;
    margin-top: 21px;
  }

  .featured-stores__container .layer {
    position: absolute;
    inset: 0;
    z-index: 999;
    background-image: linear-gradient(to left,
        rgba(255, 255, 255, 1) 7%,
        rgba(255, 255, 255, 0) 30%);
  }

  .featured-stores__slides .slick-slide {
    margin-right: 15px;
  }

  .download-app {
    margin-top: 30px;
    width: 100%;
    position: relative;
    background: url("../../src/images/background-download.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -65px;
    height: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .download-app__smartphones {
    position: absolute;
    left: -240px;
    top: 70px;
  }

  .download-app__platforms {
    display: flex;
    gap: 10px;
    position: absolute;
    left: 42px;
    bottom: 100px;
  }

  .pagaleve-payments {
    padding: 0 43px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .pagaleve-payments h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    max-width: 200px;
  }

  .pagaleve-payments h4 {
    margin: 16px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }

  .pagaleve-payments .blackphone {
    position: relative;
    margin-top: 60px;
    width: 440px;
    height: 360px;
    right: 136px;
  }

  .pagaleve-payments .greenphones {
    width: 400px;
    height: 380px;
    position: relative;
    right: 53px;
  }

  .tutorial {
    position: relative;
    margin: 150px 0 0;
    overflow: initial;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url("../../src/images/background-layer.png");
    background-repeat: no-repeat;
    background-position: -90px;
    background-size: 230% 100%;
  }

  .tutorial__smartphones {
    position: absolute;
    width: 330px;
    height: 320px;
    align-self: flex-start;
    top: -150px;
  }

  .tutorial__content {
    margin-top: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 43px;
  }

  .content-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tutorial__content h2 {
    margin-top: 15px;
    max-width: 135px;
  }

  .tutorial__content h3 {
    padding: 15px 0;
    margin-top: 15px;
    max-width: 135px;
    text-align: center;
  }

  .tutorial__content .no-tax {
    margin-top: 76px;
  }

  .tutorial__content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 43px;
  }

  .tutorial__title-1 {
    align-self: flex-start;
  }

  .tutorial.green-phones {
    position: relative;
    bottom: 40px;
  }

  .tutorial-carousel {
    display: none;
  }

  .pageleve-numbers {
    display: flex;
    flex-direction: column;
    padding: 0 43px;
    margin-top: 46px;
  }

  .desk-title {
    display: none;
  }

  .pagaleve-numbers .desk-title {
    display: none;
  }

  .pageleve-numbers h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
  }

  .pageleve-numbers p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }

  .pagaleve-number__results {
    display: grid;
    row-gap: 60px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    margin-top: 46px;
  }

  .pagaleve-number__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pagaleve-number__item .title {
    color: #da38eb;
    font-weight: 700;
    font-size: 42px;
    line-height: 64px;
    padding: 5px 0px;
  }

  .pagaleve-number__item .describe {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  .pagaleve-comments {
    margin-top: 85px;
    position: relative;
    padding: 0 43px 85px;
  }

  .pagaleve-navigation {
    position: relative;
    margin-top: 46px;
  }

  .pagaleve-slides .slick-slide img {
    width: 100%;
  }

  .pagaleve-slides .slick-slide {
    margin-right: 15px;
  }

  .cases {
    display: none;
  }

  @media (min-width: 480px) {
    .download-app {
      padding: 0 43px;
      background-size: contain;
      background-repeat: no-repeat;
      height: 600px;
    }

    .download-app__smartphones {
      left: initial;
      right: 130px;
      width: 100%;
      object-fit: contain;
      height: 400px;
    }

    .download-app__platforms {
      width: 100%;
      justify-content: center;
      left: 0px;
      bottom: 70px;
      box-sizing: border-box;
    }

    .pagaleve-payments h2,
    .pagaleve-payments h4 {
      max-width: initial;
      text-align: center;
    }

    .pagaleve-payments .blackphone {
      margin-top: 0px;
      height: 400px;
      width: 100%;
      object-fit: contain;
      right: initial;
    }

    .pagaleve-payments .greenphones {
      height: 380px;
      right: initial;
      width: 100%;
      object-fit: contain;
    }

    .tutorial {
      background-size: 260% 100%;
      background-position: -120px;
    }

    .tutorial__smartphones {
      width: 100%;
      object-fit: contain;
    }

    .tutorial__content h2 {
      width: 100%;
      max-width: initial;
      text-align: center;
    }

    .pageleve-numbers h2 {
      text-align: center;
    }

    .pagaleve-comments h2 {
      margin-bottom: 16px;
    }
  }

  @media (min-width: 550px) {
    .download-app {
      background-position: center;
    }
  }

  @media (min-width: 768px) {
    .tutorial {
      background-size: 260% 100%;
      background-position: -180px;
    }
  }

  @media (min-width: 1024px) {
    .showcase__container {
      position: relative;
      height: 624px;
    }

    .title {
      font-family: $fontText;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      color: $black;
    }

    .grid-3 {
      display: grid !important;
      grid-template-columns: 1fr minmax(900px, 1060px) 1fr;
    }

    .grid-column-2 {
      grid-column: 2/3;
      align-items: flex-end !important;
    }

    .grid-column-3 {
      grid-column: 2/3;
    }

    .banner-hero .slider {
      display: none !important;
    }

    .banner-hero .banner-installments-slider {
      display: initial !important;
    }

    .banner-item {
      margin: 0 !important;
    }

    .item {
      // background: url("../../src/images/banner-background.png");
      width: 100%;
      background-position: 0px 0px;
      position: relative;
      background-repeat: no-repeat;
      margin-right: 0;
      height: 624px;
      padding: 0 43px;
      background-size: cover;
    }

    .item .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    .banner-installments {
      display: flex !important;
      padding: 0;
      align-items: center;
      width: 100%;

      .item {
        display: flex !important;
      }
    }

    .installments-texts {
      max-width: 470px;
      min-width: 470px;
      display: flex;
      flex-direction: column;
    }

    .installments-texts h2 {
      color: #fff;
      font-size: 48px;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -1.08px;
    }

    .installments-texts strong {
      color: #da38eb;
      font-weight: 700;
    }

    .installments-texts p {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      line-height: 140%;
      margin-top: 21px;
    }

    .installments-texts span {
      color: var(--dark1);
      font-size: 18px;
      font-weight: 400;
      line-height: 22, 5px;
      margin-top: 5px;
    }

    .installments-image {
      overflow: hidden;
    }

    .installments-image img {
      // width: 600px;
      height: 605px;
      display: block;
      object-fit: cover;
    }

    .where-buy-button {
      padding: 12px 20px;
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      background: #21232f;
      border-radius: 39px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      align-self: flex-start;
      margin-top: 66px;
    }

    .background-layer-image {
      position: absolute;
      object-fit: contain;
      right: -400px;
      z-index: 1;
      display: block;
      top: -150px;
    }

    .buying-content {
      margin-top: 53px;
      padding: 0 27px;
      margin-bottom: 70px;
    }

    .buying-content h2 {
      text-align: left;
      max-width: 535px;
      padding: 0;
      font-size: 38px;
      line-height: 35px;
    }

    .buying-content__search {
      width: 100%;
      max-width: 610px;
      margin-left: 0;
    }

    .buying-content__search input {
      font-size: 24px;
      line-height: 140%;
    }

    .buying-content__search img {
      top: 9px;
    }

    .choose_category {
      display: block;
      width: 100%;
      padding: 0px 43px;
      box-sizing: border-box;
      position: relative;
      margin-top: 156px;
    }

    .choose_category__title {
      font-size: 48px;
      font-weight: 600;
    }

    .choose_category_slides {
      position: relative;
      margin-top: 100px;
    }

    .choose_category_slides .layer {
      position: absolute;
      inset: 0;
      z-index: 999;
      background-image: linear-gradient(to left,
          rgba(255, 255, 255, 1) 7%,
          rgba(255, 255, 255, 0) 30%);
    }

    .choose_category_slides .slick-slide {
      margin-right: 15px;
    }

    .dots-navigation {
      display: none;
    }

    .arrows-navigation {
      justify-content: flex-start;
      margin-top: 37px;
    }

    .prevArrow {
      position: initial;
      // transform: rotateY(180deg);
    }

    .nextArrow {
      position: initial;
    }

    .featured-stores {
      margin-top: 144px;
      margin-bottom: 144px;
    }

    .featured-title {
      display: flex;
      align-items: center;
    }

    .featured-title h2 {
      width: 100%;
      max-width: 750px;
    }

    .featured-stores__title {
      width: 100%;
      font-size: 48px;
    }

    .all_stores {
      padding: 20px 36px;
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      background: #21232f;
      border-radius: 39px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      display: block;
      white-space: nowrap;
    }

    .download-app {
      display: none;
    }

    .pagaleve-payments {
      display: none;
    }

    .pagaleve-payments-desk {
      padding: 0 0 0 43px;
    }

    .tutorial-carousel {
      background: url("../../src/images/arrow-carousel.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      height: 700px;
      justify-content: center;
      padding-left: 29px;
      display: flex;
      flex-direction: column;
    }

    .tutorial-carousel-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .tutorial {
      background: none;
      display: block;
      flex-direction: row;
      padding-bottom: 0;
      margin-top: 0;
    }

    .c-slide__item {
      display: grid !important;
      grid-template-columns: 600px 1fr;
      align-content: center;
    }

    .c-slide__item .item-infos h2 {
      font-size: 48px;
      max-width: 400px;
      line-height: 53px;
    }

    .c-slide__item .item-infos h4 {
      font-size: 24px;
      margin-top: 26px;
      font-weight: 600;
      line-height: 28px;
      width: 100%;
      max-width: 320px;
    }

    .where-buy {
      padding: 19px 57px;
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      background: #21232f;
      border-radius: 39px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      position: relative;
      margin-bottom: 51px;
      margin-top: 54px;
      max-width: max-content;
    }

    .c-slide__item .item-infos p {
      margin-top: 8px;
      color: #21232f;
      font-size: 16px;
      line-height: 28px;
    }

    .c-slide__item .item-image img {
      max-width: 520px;
      object-fit: contain;
    }

    .tutorial .tutorial__smartphones {
      display: none;
    }

    .tutorial__title-1 {
      display: none;
    }

    .tutorial__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
    }

    .content-item {
      display: grid;
      grid-template-rows: 270px auto auto;
      align-items: center;
    }

    .content-item img {
      grid-row: 1/2;
    }

    .no-tax {
      margin-top: 0;
    }

    .tutorial__content h3 {
      color: #9600a6;
      width: auto;
      max-width: initial;
      text-align: center;
      grid-row: 2/3;
    }

    .content-item p {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      width: 100%;
      max-width: 250px;
      grid-row: 3/4;
    }

    .pagaleve-news {
      display: block;
      width: 100%;
      box-sizing: border-box;
      margin-top: 135px;
      padding: 0 43px;
      position: relative;
    }

    .pagaleve-news h2 {
      font-size: 48px;
      max-width: 603px;
    }

    .grid-news {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto auto;
      margin-top: 95px;
      max-width: 1020px;
    }

    .grid div {
      position: relative;
    }

    .grid div img {
      width: 100%;
    }

    .grid div img.arrow-image {
      width: 14px;
    }

    .label,
    .img-text,
    .link-action {
      position: absolute;
    }

    .label {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      border-radius: 40px;
      background: #12f2aa;
      padding: 7px 14px;
      top: 30px;
      right: 40px;
    }

    .img-text {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: 28.992px;
      text-transform: uppercase;
      bottom: 100px;
      left: 30px;
      max-width: 180px;
    }

    .link-action {
      color: #12f2aa;
      font-size: 14px;
      font-weight: 700;
      line-height: 28.992px;
      text-transform: uppercase;
      text-decoration: none;
      bottom: 40px;
      left: 30px;
      max-width: 180px;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .h-image {
      grid-column: 1/2;
      grid-row: 1/3;
      display: flex;
      position: relative;
    }

    .s1-image {
      grid-column: 2/3;
      grid-row: 1/1;
      display: flex;
      position: relative;
    }

    .s2-image {
      grid-column: 3/4;
      grid-row: 1/1;
      display: flex;
      position: relative;
    }

    .w-image {
      grid-column: 2/4;
      grid-row: 2/2;
      display: flex;
      position: relative;
    }

    .pageleve-numbers {
      margin-top: 144px;
    }

    .pageleve-numbers .mob-title {
      display: none;
    }

    .pageleve-numbers .desk-title {
      font-size: 48px;
      width: 100%;
      max-width: 664px;
      text-align: left;
    }

    .pagaleve-number__results {
      grid-template-columns: repeat(auto-fill, minmax(170px, 190px));
    }

    .pagaleve-comments h2 {
      font-size: 48px;
      width: 100%;
      max-width: 291px;
      line-height: 52.984px;
    }

    .pagaleve-slides {
      margin-top: 40px;
    }

    .cases {
      display: block;
      background: white;
    }

    .cases .title {
      font-size: 48px;
      padding: 39px 0;
    }

    .cases p {
      font-size: 22px;
      line-height: 150%;
      color: $gray2;
      padding-bottom: 40px;
    }

    .slide_item {
      display: grid;
      grid-template-columns: 450px 1fr;
      width: 100%;
      max-width: 700px;
      border-radius: 32px;
      border: 2px solid #24359c;
      overflow-x: hidden;
    }

    .slide_item .slide-item-text {
      display: flex;
      flex-direction: column;
      padding: 53px 20px 29px 52px;
      justify-content: space-between;
    }

    .slide_item .partner_logo {
      width: 100%;
      max-width: 170px;
    }

    .slide_item p {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: $gray2;
      margin-top: 26px;
      padding-bottom: 0;
    }

    .slide_item a {
      align-self: flex-start;
      padding: 12px 20px;
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      background: #21232f;
      border-radius: 39px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      display: block;
      white-space: nowrap;
      margin-top: 21px;
    }

    .slide_item .image {
      display: flex;
      width: 100%;
      max-width: 378px;
    }

    .slick-slide .image img {
      object-fit: cover;
      width: 100%;
    }
  }
}

.banner-hero {
  .slider {
    display: block;
  }

  .banner-installments-slider {
    display: none;
  }

  @media (min-width: 1024px) {
    .slider {
      display: none;
    }

    .banner-installments-slider {
      display: initial;
      position: relative;
      .item {
        .grid-column-2{
          display: flex;
        }
      }
      // .slick-list {
      //   overflow-y: visible;
      //   height: 100%;
      //   -ms-overflow-style: none;  /* IE and Edge */
      //   scrollbar-width: none;  /* Firefox */
      //   &::-webkit-scrollbar{
      //     display: none;
      //   }
      // }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

  .dots-navigation {
    left: 5px;
    bottom: 5px;
    li {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #fff;
      margin-right: 3px;
      position: relative;
      &::before{
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 100%;
        border: 1px solid #fff;
        position: absolute;
        left: -4px;
        bottom: -4px;
      }
    }
    .slick-active {
      background-color: #bbbbbc;
      &::before{
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 100%;
        border: 1px solid #bbbbbc;
        position: absolute;
        left: -4px;
        bottom: -4px;
      }
    }
  }
}