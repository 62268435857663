/* INICIO BANNER FORM */

/* INICIO DESCRIÇÃO FORM */
.banner-form {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f6f6f6;
}

.banner-form .banner-form-content {
  margin: 0 26px;
  box-sizing: border-box;
  padding: 15px;
  background: none;
}

.banner-form .background-after {
  display: none;
  position: absolute;
  right: -300px;
  top: -200px;
}

.banner-form .form-description-content h1 {
  color: #21232f;
  font-family: var(--font-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.6px;
}

.banner-form .form-description-content h1 .green-text {
  color: #14de9d;
  font-weight: 700;
}

.banner-form .form-description-content p {
  color: #21232f;
  font-family: var(--open-sans);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-top: 20px;
}

.banner-form .content-description-images {
  margin-top: 40px;
  display: flex;
  gap: 20px;
}

.banner-form .content-description-images img {
  object-fit: contain;
  height: 10px;
}

/* FIM DESCRIÇÃO FORM */

/* INICIO INPUTS */

.form-description {
  margin-top: 60px;
}

.form-description .input-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 24px;
  position: relative;
}

.input-container .input {
  position: relative;
  width: 100%;
}

.input-container .input img {
  position: absolute;
  top: 40%;
  right: 0;
  display: none;
}

.input-container label,
.input-container input,
.input-container textarea {
  color: #21232f;
  font-family: var(--font-text);
  width: 100%;
}

.input-container textarea {
  height: 160px;
  resize: none;
}

.input-container input::placeholder,
.input-container textarea::placeholder {
  color: #667085;
  font-family: var(--font-text);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.input-container label {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 5px;
}

.input-container input {
  padding: 10px 0;
}

.input-container input,
.input-container textarea {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  border-bottom: 1px solid #eaecf0;
  background: #f6f6f6;
}

.invalid {
  input {
    border: 1px solid red !important;
  }

  .error {
    color: red;
    text-align: right;
    line-height: normal;
    font-size: 13px;
  }
}

.form-description .form-button {
  border-radius: 30px;
  border: 1px solid #000;
  background: #000;

  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-family: var(--font-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  max-width: 292px;

  margin: 0 auto;
}

/* FIM INPUTS */

/* FIM BANNER FORM */

/* INICIO ESTILIZAÇÃO SLIDES */

.slide-keep-update,
.slide-success-cases {
  position: relative;
  margin-top: 30px;
}

.keep-update-items .slick-slide,
.success-cases-items .slick-slide {
  margin-right: 16px;
}

.dots-navigation,
.arrows-navigation {
  display: flex;
  justify-content: center;
}

.arrow {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
}

.arrow.disabled {
  cursor: initial;
}

.arrow.disabled svg path {
  stroke: #cccdce;
}

.prevArrow {
  transform: rotateY(180deg);
  left: 0;
}

.nextArrow {
  right: 0px;
}

.dots-navigator {
  display: flex !important;
  justify-content: space-around;
  width: 40%;
}

.dots-navigator ul {
  display: flex;
  justify-content: space-around;
}

.dots-navigator li button {
  display: none;
}

.dots-navigator li::marker {
  content: url(Images/not-selected-gray.svg);
}

.dots-navigator li.slick-active::marker {
  content: url(Images/selected-gray.svg);
}

.dots-class {
  position: absolute;
  top: 38%;
  right: 0;
  padding-right: 23px;
}

.dots-class li button {
  display: none;
}

.slider.slick-initialized .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider .slick-slide img {
  width: 100%;
}

/* FIM ESTILIZAÇÃO SLIDES */

main {
  width: 100%;
  transition: all 0.2s linear;
  filter: blur(0px);
  padding: 0px 0px 0px;
  background: #f9fafb;
  overflow: hidden;
}

section {
  overflow: hidden;
  box-sizing: border-box;
}

/* INICIO BENEFICIOS PIX PARCELADO */

.pix-benefits {
  background: #f9fafb;
  width: 100%;
  display: grid !important;
  padding-bottom: 77px;
}

.pix-benefits-content {
  padding: 105px 26px 0;
  display: grid !important;
}

.pix-benefits h1 {
  color: #000;
  font-family: var(--font-text);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.pix-benefits-item {
  margin-top: 60px;
}

.pix-benefits-description {
  margin-top: 35px;
  gap: 9px;
}

.pix-benefits-item,
.pix-benefits-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.pix-benefits-item img {
  max-width: 220px;
  width: 100%;
}

.pix-benefits-description .description-title-mobile,
.pix-benefits-description .description-title {
  color: #9600a6;
  text-align: center;
  font-family: var(--font-text);
  font-size: 23px;
  font-weight: 700;
  line-height: 31.484px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
}

.pix-benefits-description .description-title-desktop {
  color: #9600a6;
  text-align: center;
  font-family: var(--font-text);
  font-size: 23px;
  font-weight: 700;
  line-height: 31.484px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  display: none;
}

.pix-benefits-description .description {
  color: #000;
  text-align: center;
  font-family: var(--font-text);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 0 12px;
}

/* FIM BENEFICIOS PIX PARCELADO  */

/* INICIO PLATAFORMAS PARCEIRAS */

.partnership-platforms {
  display: none;
}
/* FIM PLATAFORMAS PARCEIRAS */

/* INICIO SINONIMO DE PARCELAMENTO */

.synonym-installment {
  margin: 120px 26px 0;
  grid-template-columns: 1fr 1160px 1fr !important;
}

.installment-text-content .installment-title,
.installment-text-content .installment-description {
  font-family: var(--font-text);
  color: #000;
}

.installment-text-content .installment-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 130%;
  padding-right: 50px;
}

.installment-text-content .installment-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-right: 76px;
  margin-top: 16px;
}

.installment-info-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 54px;
}

.installment-info-content .info-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.installment-info-content .info-content-item img {
  height: 100px;
}

.installment-info-content .info-content-item .title,
.installment-info-content .info-content-item .sub-title,
.installment-info-content .info-content-item .describe {
  font-family: var(--font-text);
  text-align: center;
}

.installment-info-content .info-content-item .title,
.installment-info-content .info-content-item .sub-title {
  color: #da38eb;
  font-weight: 700;
}

.installment-info-content .info-content-item .title {
  font-size: 49px;
  line-height: 50.984px;
}

.installment-info-content .info-content-item .sub-title {
  font-size: 20px;
  line-height: 63.984px;
}

.installment-info-content .info-content-item .describe {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

/* FIM SONINIMO DE PARCELAMENTO */

/* INICIO TUDO PRA VC FICAR */

.keep-update-content {
  display: flex;
  flex-direction: column;
  margin: 66px 26px 0;
}

.keep-update-content h2 {
  color: #101828;
  font-family: var(--font-text);
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.keep-update-items {
  margin-top: 45px;
}

.keep-update-item {
  border-radius: 32px;
  position: relative;
  overflow: hidden;
}

.keep-update-item .label,
.keep-update-item .description,
.keep-update-item .link-action {
  position: absolute;
}

.keep-update-item .image {
  width: 307px;
  height: 383px;
  object-fit: cover;
}

.keep-update-item .label {
  top: 32px;
  right: 28px;
  color: #000;
  text-align: center;
  font-family: var(--font-text);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  border-radius: 40px;
  background: #12f2aa;
  padding: 6px 20px;
}

.keep-update-item .description {
  left: 33px;
  bottom: 72px;
  color: #fff;
  font-family: var(--font-text);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.992px;
  text-transform: uppercase;
}

.keep-update-item .link-action {
  left: 33px;
  bottom: 42px;
  color: #12f2aa;
  font-family: var(--font-lato);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* FIM TUDO PRA VC FICAR */

/* INCIO CASOS DE SUCESSO */

.success-cases-content {
  display: flex;
  flex-direction: column;
  margin: 66px 26px 0;
}

.success-cases-content h2 {
  color: #101828;
  font-family: var(--font-text);
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.success-cases-content p {
  color: #21232f;
  font-family: var(--open-sans);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 18px;
}

.success-cases-items {
  margin-top: 45px;
  .post {
    max-width: 344px;
    max-height: 362px;
  }
}

.success-cases-item {
  border-radius: 32px;
  position: relative;
  overflow: hidden;
}

.success-cases-item .label,
.success-cases-item .description,
.success-cases-item .link-action {
  position: absolute;
}

.success-cases-item .image {
  width: 307px;
  height: 383px;
  object-fit: cover;
}

.success-cases-item .label {
  top: 32px;
  left: 33px;
}

.success-cases-item .description {
  left: 33px;
  bottom: 72px;
  color: #fff;
  font-family: var(--font-text);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.992px;
  text-transform: uppercase;
}

.success-cases-item .link-action {
  left: 33px;
  bottom: 42px;
  color: #12f2aa;
  font-family: var(--font-lato);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* FIM CASOS DE SUCESSO */

/* INICIO PERGUNTS FREQUENTES */

.faq {
  margin: 76px 26px 0;
}

.faq .faq-title {
  color: #101828;
  font-family: var(--font-text);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.faq .faq-description {
  color: #21232f;
  font-family: var(--font-text);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-top: 20px;
}

.faq-items {
  margin-top: 33px;
  padding: 0 15px;
}

.faq-item {
  display: flex;
  padding: 10px 0 25px;
  border-bottom: 1px solid #eaecf0;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-item .faq__question {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.faq-item .faq__question h5 {
  color: #101828;
  font-size: 16px;
  font-weight: 700;
}

.faq-item .faq__question p {
  color: #475467;
  font-size: 16px;
  margin-top: 8px;
  line-height: 24px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.faq-item .faq-btn-toggle {
  background: none;
  border-radius: 100%;
  padding: 1px 4px;
  border: 2px solid #98a2b3;
  cursor: pointer;
  align-self: flex-start;
  margin-left: 24px;
}

.faq-item .faq-btn-toggle:after {
  content: "\002B";
  color: #98a2b3;
  font-weight: bold;
}

.faq-item .active:after {
  content: "\2212";
}

.still-doubts {
  margin: 77px 26px 0;
  background: #f9fafb;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.still-doubts-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.doubts__image .avatar-1 {
  margin-right: -20px;
  width: 86px;
}

.doubts__image .avatar-2 {
  position: relative;
  z-index: 1;
  width: 96px;
}

.doubts__image .avatar-3 {
  width: 86px;

  margin-left: -20px;
}

.still-doubts .doubts__title {
  color: #101828;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-top: 32px;
}

.still-doubts .doubts__description {
  color: #475467;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.still-doubts .doubts__button {
  border-radius: 30px;
  border: 1px solid var(--pink2);
  background: var(--pink2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 12px 20px;
  color: white;
  font-size: 16px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  max-width: 179px;
  margin-top: 32px;
  white-space: nowrap;
}

/* FIM PERGUNTAS FREQUENTES */

/* INICIO FORM B2B */

.form-b2b-content {
  margin: 110px 26px 0;
}

.form-b2b-title,
.form-b2b-title span {
  color: #21232f;
  font-size: 28px;
}

.form-b2b-title {
  font-weight: 600;
  line-height: 40px;
}

.form-b2b-title span {
  font-weight: 700;
  line-height: 40px;
}

@media (min-width: 768px) {
  .pix-benefits h1,
  .installment-text-content .installment-title,
  .faq .faq-title {
    text-align: center;
  }

  .installment-text-content .installment-description {
    text-align: center;
    padding: 0;
  }
}

@media (min-width: 1024px) {
  /* INICIO BANNER DESCRIÇÃO E FORM */

  .banner-form {
    display: grid;
    grid-template-columns: 1fr 1155px 1fr;
    padding-bottom: 50px;
  }

  .banner-form .banner-form-content {
    margin: 90px 0 0;
    padding: 0px;
    display: flex;
    gap: 50px;
    grid-column: 2/3;
    justify-content: space-between;
  }

  .banner-form .form-description-content {
    width: 100%;
    max-width: 420px;
  }

  .banner-form .form-description-content h1 {
    font-size: 46px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.92px;
  }

  .banner-form .form-description-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
    margin-top: 20px;
  }

  .banner-form .content-description-images {
    margin-top: 92px;
  }

  .banner-form .content-description-images img {
    height: initial;
  }

  /* FIM DESCRIÇÃO FORM */

  /* INICIO INPUTS */

  .form-description {
    width: 100%;
    margin-top: 0px;
  }

  .form-description .input-group {
    display: flex;
    gap: 32px;
    width: 100%;
  }

  .form-description .input-group .input-container {
    width: 50%;
  }

  .input-container textarea {
    height: 100px;
    /* resize: none; */
  }

  .input-container input::placeholder,
  .input-container textarea::placeholder {
    max-width: 300px;
  }

  .input-container input,
  .input-container textarea {
    background: none;
  }

  .form-description .form-button {
    margin: 0;
    margin-left: auto;
  }

  /* FIM DESCRIÇÃO E FORM */

  main {
    width: 100%;
    background: white;
  }

  section {
    display: grid;
    grid-template-columns: 1fr 1160px 1fr;
  }

  /* INICIO BENEFICIOS PARCELAMENTO PIX */

  .pix-benefits .pix-benefits-content {
    grid-column: 2/3;
    padding: 105px 0px 0;
    display: grid;
    flex-direction: column;
    background: #f9fafb;
  }

  .pix-benefits h1 {
    color: #333;
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    line-height: 52.984px;
    letter-spacing: 0.96px;
    text-align: left;
    width: 550px;
  }

  .pix-benefits-items {
    margin-top: 90px;
    display: grid;
    grid-template-columns: repeat(3, 338px);
    gap: 20px;
    justify-content: space-between;
  }

  .pix-benefits-item {
    margin-top: 0px;
    justify-content: center;
  }

  .pix-benefits-description {
    margin-top: 40px;
    gap: 29px;
  }

  .pix-benefits-item img {
    max-width: initial;
    object-fit: none;
    height: 280px;
  }

  .pix-benefits-description .description-title-mobile {
    display: none;
  }

  .pix-benefits-description .description-title-desktop {
    display: block;
  }

  .pix-benefits-description .description {
    padding: 0;
    margin-top: auto;
  }

  /* FIM BENEFICIOS PARCELAMENTO PIX */

  /* INICIO PLATAFORMAS PARCEIRAS */

  .partnership-platforms {
    margin: 126px 0px 0;
    display: grid;
    background: white;
  }

  .partnership-platforms .partnership-plataform-content {
    grid-column: 2/3;
  }

  .partnership-platforms h1 {
    color: #333;
    font-family: var(--font-text);
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 52.984px;
    letter-spacing: 0.96px;
  }

  .partnership-platforms .slides-platform-items {
    margin-top: 79px;
  }

  .partnership-platforms .plataform-item {
    margin-right: 50px;
    img {
      max-width: 250px;
      height: auto;
    }
  }

  /* FIM PLATAFORMAS PARCEIRAS */

  /* INICIO SINONIMO DE PARCELAMENTO */

  .synonym-installment {
    margin: 177px 0px 70px;
    background: white;
    grid-template-columns: 1fr minmax(900px, 1060px) 1fr !important;
  }

  .synonym-installment-content {
    grid-column: 2/3;
  }

  .installment-text-content .installment-title,
  .installment-text-content .installment-description {
    font-family: var(--font-text);
  }

  .installment-text-content .installment-title {
    font-size: 48px;
    font-weight: 600;
    color: #333;
    line-height: 52.984px;
    letter-spacing: 0.96px;
    text-align: left;
    max-width: 680px;
  }

  .installment-text-content .installment-description {
    font-size: 22px;
    line-height: 150%;
    color: #666;
    text-align: left;
  }

  .installment-info-content {
    grid-template-columns: repeat(5, 1fr);
    margin-top: 74px;
  }

  .installment-info-content .info-content-item .title {
    font-size: 46px;
    line-height: 50.984px;
  }

  .installment-info-content .info-content-item .sub-title {
    font-size: 26px;
    line-height: 63.984px;
  }

  /* FIM SONINIMO DE PARCELAMENTO */

  /* INICIO TUDO PARA VOCE... */

  .keep-update-content {
    display: grid;
    margin: 200px 0px 0;
    background: white;
  }

  .keep-update-alignment {
    grid-column: 2/3;
  }

  .keep-update-content h2 {
    color: #333;
    font-weight: 600;
    line-height: 52.984px;
    letter-spacing: 0.96px;
    max-width: 575px;
  }

  .keep-update-items {
    margin-top: 95px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 6px;
  }

  .keep-update-item {
    border-radius: 32px;
    position: relative;
    overflow: hidden;
    display: flex;
  }

  .h-image.keep-update-item {
    grid-column: 1/2;
    grid-row: 1/3;
  }

  .s1-image.keep-update-item {
    grid-column: 2/3;
    grid-row: 1/1;
  }

  .s2-image.keep-update-item {
    grid-column: 3/4;
    grid-row: 1/1;
  }

  .w-image.keep-update-item {
    grid-column: 2/4;
    grid-row: 2/2;
  }

  .keep-update-item .image {
    width: initial;
    height: initial;
    object-fit: cover;
  }

  /* FIM TUDO PARA VOCE... */

  /* INICIO CASOS DE SUCESSO */

  .success-cases-content {
    display: grid;
    margin: 56px 0px 0;
    background: white;
  }

  .success-cases-alignment {
    grid-column: 2/3;
    position: relative;
  }

  .success-cases-content h2 {
    color: #333;
    font-size: 48px;
    font-weight: 600;
    line-height: 52.984px;
    letter-spacing: 0.96px;
  }

  .success-cases-content p {
    color: #666;
    font-family: var(--open-sans);
    font-size: 22px;
    line-height: 150%;
    margin-top: 18px;
  }

  .slide-success-cases .dots-navigation {
    display: none;
  }

  .slide-success-cases .arrows-navigation {
    margin-top: 50px;
  }

  .slide-success-cases .arrows-navigation .nextArrow {
    left: 30px;
    right: unset;
  }

  /* FIM CASOS DE SUCESSO */

  /* INICIO PERGUNTAS FREQUENTES */

  .faq {
    margin: 132px 0 0;
    background: white;
  }

  .faq-content {
    grid-column: 2/3;
  }

  .faq .faq-title {
    color: #101828;
    font-family: var(--font-text);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.72px;
  }

  .faq .faq-description {
    color: #475467;
    text-align: center;
    font-family: var(--font-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-top: 20px;
  }

  .faq-items {
    margin-top: 64px;
    padding: 0 15px;
  }

  .faq-item {
    display: flex;
    padding: 10px 0 25px;
    border-bottom: 1px solid #eaecf0;
  }

  .faq-item:last-child {
    border-bottom: none;
  }

  .faq-item .faq__question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .faq-item .faq__question h5 {
    color: #101828;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }

  .faq-item .faq__question p {
    color: #475467;
    font-size: 16px;
    margin-top: 8px;
    line-height: 24px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .faq-item .faq-btn-toggle {
    background: none;
    border-radius: 100%;
    padding: 1px 4px;
    border: 2px solid #98a2b3;
    cursor: pointer;
    align-self: flex-start;
    margin-left: 24px;
  }

  .faq-item .faq-btn-toggle:after {
    content: "\002B";
    color: #98a2b3;
    font-weight: bold;
  }

  .faq-item .active:after {
    content: "\2212";
  }

  /* FIM PERGUNTAS FREQUENTES */

  /* INICIO AINDA HÁ DÚVIDAS */

  .still-doubts {
    margin: 77px 26px 0;
    display: grid;
    background: white;
  }

  .still-doubts .still-doubts-content {
    padding: 40px 0;
    background: #f9fafb;
    grid-column: 2/3;
  }

  .still-doubts .doubts__image {
    display: none;
  }

  /* FIM AINDA HÁ DUVIDAS */

  /* INICIO FORM B2B BOTTOM*/

  section.form-b2b {
    display: none;
  }
}

@media (min-width: 1440px) {
  .banner-form .background-after {
    display: initial;
  }
}

@media (max-width: 1040px) {
  .slide-success-cases {
    margin-top: 60px;
  }
}
