.download-app {
  margin-top: 30px;
  width: 100%;
  position: relative;

  @media (min-width: 1024px) {
    display: none;
  }
}

.download-app__background {
  position: relative;
  left: -45px;
}

.download-app__smartphones {
  position: absolute;
  left: 0;
  top: 70px;
}

.download-app__platforms {
  display: flex;
  gap: 10px;
  position: absolute;
  left: 42px;
  bottom: 100px;
}

.app-store {
  display: flex;
  gap: 10px;
  margin-top: 50px;
  justify-content: center;

  & img {
    height: 43px;
  }
}

.qr_code {
  display: none;
}

@media (min-width: 1024px) {
  .download-container {
    padding-top: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: 624px 1200px;
    background-repeat: no-repeat;
    background-position: -170px 0px;
    height: 703px;
    background-color: white;
  }

  .download-container.lazyload {
    background: none;
  }

  .download-container:not(.lazyload) {
    background: url("../../src/images/download-app-desktop.png") no-repeat -170px 0;
    background-size: 624px 1200px;
  }

  .download-holder {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .download-container__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 340px;

    h2 {
      font-size: 48px;
      text-align: left;
    }

    p {
      text-align: left;
      color: #21232f;
      font-size: 18px;
      font-family: var(--open-sans);
      line-height: 140%;
      margin-top: 0;
      margin-bottom: 30px;
    }

    div {
      justify-content: flex-start;
      margin: 0;
      display: flex;
      gap: 15px;
    }

    .qr_code {
      display: block;
      img{
        max-width: 200px;
      }
    }

    .app-store {
      display: none;
    }
  }

  .download-container__stores {
    background: none;
    margin-top: 0px;
    justify-content: center;

    .smartphones {
      object-fit: cover;
      left: -450px;
      height: 622px;
      max-width: initial;
      overflow: hidden;
      position: relative;
    }
  }
}
.pageleve-download__container {
  background: url("../../src/images/arrow-2.png");
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.background-image-1 {
  background: url("../../src/images/download--app-content.svg");
}
.background-image-2 {
  background: url("../../src/images/arrow-2.png");
}

@media (max-width: 1024px) {
  .download-container {
    padding: 85px 0px 0;
    overflow: initial;
    background: url("../../src/images/download-app-desktop.png");
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: -200px 250px;
    display: flex;
    flex-direction: column;
  }
  .download-container__content {
    padding: 0px 43px;
    order: 2;
  }
  .download-container__stores {
    order: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    justify-content: center;

    & img {
      height: 100%;
      width: 100%;
      max-width: 700px;
      object-fit: contain;
      margin-top: 40px;
    }
  }
}

@media (max-width: 768px) {
  .download-container__stores {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 450px;
    justify-content: center;
    align-items: flex-start;
    order: 1;
    & img {
      height: 100%;
      width: 100%;
      max-width: 500px;
      object-fit: contain;
    }
  }
}
