@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
}

h1 {
  font-family: 'Open Sans', sans-serif;
}


$black : #000000;
$dark1 : #21232f;
$dark2 : #24359c;
$gray: #999999;
$gray2: #666666;
$pink : #fe35f1;
$pink2 : #da38eb;
$green : #12F2AA;
$openSans: "Open Sans", sans-serif;
$fontText: "Quicksand", sans-serif;
$fontInter: "Inter", sans-serif;

:root {
  --background: #f9fafb;
  --black: #000000;
  --white: #fff;
  --dark1: #21232f;
  --dark2: #24359c;
  --gray: #999999;
  --gray2: #666;
  --gray3: #475467;
  --gray4: #333;
  --gray5: #888;
  --pink: #fe35f1;
  --pink2: #da38eb;
  --open-sans: "Open Sans", sans-serif;
  --font-text: "Quicksand", sans-serif;
  --font-inter: "Inter", sans-serif;
  --font-public: "Public Sans", sans-serif;
  }
