.search__wrapper {
    display: flex;
    max-height: 300px;
    flex-direction: column;
    max-width: 310px;
    justify-content: center;
    align-items: center;
    a{
        position: relative;
        bottom: unset;
    }
}