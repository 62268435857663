body .pagaleve-comments{
  display: none !important;
}
.pagaleve-grid {
  grid-template-rows: auto auto;
}

.slide-navigation-comment {
  grid-row: 2/3;
}

.comment-item {
  position: relative;
}

.comment-item button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: none;
  border: none;
}

.comment-item video {
  border-radius: 30px;
  box-shadow: 1px 1px 6px 1px #d3d3d32e;
  border: 1px solid #d3d3d32e;
}

.fade-play-btn {
  animation: fadeBtn 0.5s forwards;
}
.play-btn{
  width: 100%;
  height: 100%;
  left: 0 !important;
  bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes fadeBtn {
  0% {
    opacity: 1;
  } 
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 1024px) {
  .pagaleve-comments {
    margin-top: 85px;
    position: relative;
    padding: 0 43px 85px;
    .description{
      padding-top: 30px;
    }
  }

  .pagaleve-slides {
    margin-top: 40px;
    grid-column: 1/4;
  }

  .pagaleve-slides .slick-slide {
    margin-right: 15px;
  }

  .slide-navigation-comment {
    grid-column: 1/4;
    display: grid;
    grid-template-columns: 1fr 900px 1fr;
    margin-top: 50px !important;
  }

  .arrows-navigation {
    grid-column: 1/2;
  }

  .dots-navigation {
    display: block !important;
    grid-column: 2/3;
    width: 200px !important;
    justify-self: flex-end;

    ul {
      margin-bottom: 0px;
      width: 100%;
    }
  }
}

@media (min-width: 1280px) {
  .pagaleve-comments {
    padding-right: 0;
  }
}

@media (max-width: 1024px) {
  .pagaleve-comments {
    display: flex;
    flex-direction: column;
    padding: 0 43px;
    margin-top: 46px;
    .pagaleve-slides {
      .slick-slide {
        margin-right: 15px;
      }
    }
  }
}
