.areas__container {
  padding: 84px 0;
  margin: 0 0 40px;
  display: grid;
  grid-template-columns: 1fr 1055px 1fr;
  background-color: #f9fafb;

  .area-wrapper {
    display: flex;
    flex-direction: column;
    grid-column-start: 2;

    .areas__heading {
      margin-bottom: 40px;

      h2 {
        font-family: var(--font-text);
        font-weight: 600 !important;
        font-size: 48px !important;
        line-height: 53px;
        color: var(--black);
        width: unset;
      }

      p {
        font-family: var(--font-text);
        font-weight: 400 !important;
        font-size: 18px !important;
        line-height: 33px;
        color: var(--black);
        width: unset;
        margin-top: 8px;
      }
    }

    .slide-navigation-carousel {
      margin-top: 60px;
    }
  }

  .areas__carousel {
    display: flex;
    margin-top: 40px;

    .area__block {
      text-decoration: none;
      box-sizing: border-box;
      padding-right: 20px;
      width: 264px !important;

      img {
        margin-bottom: 20px;
        filter: grayscale(100%);
        width: 200px;
      }

      h3 {
        margin: 10px 0;
        color: var(--black);
        font-size: 22px;
        line-height: 28px;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: var(--black);
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 1024px) {
  .areas__container {
    grid-template-columns: 20px 1fr 20px;

    .area-wrapper {
      grid-column: 1/3;
      padding: 0 20px;

      .areas__heading {
        h2 {
          font-weight: 700;
          font-size: 22px !important;
          line-height: 28px;
          color: #000;
        }
      }
    }
  }
}
