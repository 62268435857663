@media (min-width: 1024px) {
  .tutorial__container {
    h2 {
      font-size: 48px;
      max-width: 400px;
      line-height: 53px;
      min-width: 100%;
      margin-bottom: 92px;
    }

    .tutorial__smartphones,
    .tutorial__title-1 {
      display: none;
    }

    .tutorial__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0;
      margin-top: 0;

      .content-item {
        display: grid;
        grid-template-rows: 270px auto auto;
        align-items: center;
        justify-items: center;

        img {
          grid-row: 1/2;
          max-height: 236px;
        }

        h3 {
          color: #9600a6;
          width: auto;
          max-width: initial;
          text-align: center;
          grid-row: 2/3;
          padding: 15px 0;
          margin-top: 15px;
          font-family: $fontText;
          font-size: 18px;
          font-weight: bold;
        }

        p {
          font-family: $fontText;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          width: 100%;
          max-width: 250px;
          grid-row: 3/4;
          margin-bottom: 43px;
          min-height: 64px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .tutorial-carousel {
    background-image: unset;
    display: none;

    .c-steps {
      display: none;
    }
  }

  .tutorial__content {
    flex-direction: column;

    .content-item {
      h3 {
        color: #9600a6;
        font-weight: bold;
        font-size: 23px;
        max-width: unset;
      }

      p {
        text-align: center;
      }
    }
  }
}
