.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.header__security {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 16px 0;
  color: $black;
  background: rgba(169, 39, 202, 0.1);
}

.security__link {
  color: $black;
  font-weight: 600;
  text-decoration-line: underline;
}

.security__description {
  margin-bottom: 0;
}

.header__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 29px 21px 44px;
}

.menu__navigation {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;
  background: #fff;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
  overflow: scroll;
  padding: 21px 29px 21px 24px;
  box-sizing: border-box;
  width: 80%;
  max-width: 500px;
  z-index: 10;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.54, 0.74);
}

.menu__logo {
  width: 152px;
}

.menu-open .menu__navigation {
  left: 0;
  overflow: hidden;
}

.menu__nav,
.menu__link-actions {
  display: flex;
  flex-direction: inherit;
}

.menu__nav {
  justify-content: space-between;
}

nav ul {
  list-style: none;
  padding: 0;
  li{
    margin-bottom: 22px;
  }
}

// .menu-options__links a {}

nav ul li a {
  font-family: $fontText;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: $gray;
  text-decoration: none;
  transition: color 0.2s linear;
}

nav ul li a:hover {
  color: $pink;
}

.separator {
  display: none;
}

.menu__link-actions {
  margin-top: 29px;
}

.sign-in,
.download-app {
  font-family: $fontText;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: $dark1;
  text-decoration: none;
  margin-top: 30px;
}

.retailers {
  background: #21232f;
  border-radius: 39px;
  color: white;
  text-decoration: none;
  padding: 22px 36px 20px;
  font-family: $fontText;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-top: 26px;
  width: 205px ;
  white-space: nowrap;
  text-align: center;
}

.menu__company-info {
  margin-top: 90px;
}

.menu__company-info .company__social-medias img {
  width: 22px;
  margin-right: 24px;
}

.menu__company-info .company__social-medias {
  margin-bottom: 12px;
}

.menu__company-info .company__social-medias a {
  // margin-right: 16px;
  text-decoration: none;
}

.menu__company-info .company__name p {
  font-family: $openSans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;

  color: $black;
  margin: 0;
}

.menu__button-toggle {
  cursor: pointer;
}

.menu__button-toggle div {
  width: 18px;
  height: 2px;
  border-radius: 5px;
  background: black;
  margin-bottom: 3px;
  transition: all 0.3s linear;
}

.menu__button-toggle .bar-3 {
  margin-bottom: 0px;
}

.menu-open .menu__nav {
  transform: translate(0px, 0px);
}

.menu-open .menu__button-toggle .bar-2 {
  opacity: 0;
}

.menu-open .menu__button-toggle .bar-1 {
  transform: translate(0, 4px) rotate(-45deg);
}

.menu-open .menu__button-toggle .bar-3 {
  transform: translate(0, -6px) rotate(45deg);
}

@media (min-width: 1024px) {
  .header {
    overflow: hidden;
    background: white;
    position: relative;
    z-index: 2;
    display: flex;
  }

  .header__security {
    display: flex;
  }

  .header__menu {
    display: flex;
    width: 100%;
    max-width: 1060px;
    margin: 0 auto;
    align-items: center;
    padding: 26px 43px;
  }

  .header__menu .logo {
    display: none;
  }

  .menu__navigation {
    position: initial;
    inset: 0;
    height: initial;
    width: 100%;
    max-width: initial;
    box-shadow: none;
    align-items: center;
    flex-direction: row;
    gap: 15px;
    overflow: hidden;
    z-index: 0;
    transition: none;
    padding: 0;
  }

  .menu__navigation .menu__logo {
    width: 150px;
    margin-right: 30px;
  }

  .menu__nav {
    align-items: center;
    width: 100%;
  }

  .menu__nav nav ul {
    display: flex;
    gap: 10px;
    margin-bottom: 0;
  }

  .menu__nav nav ul li {
    margin: 0;
  }

  .menu__nav nav ul li a {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 0;
  }

  .menu__nav nav ul li:nth-child(5),
  .menu__nav nav ul li:nth-child(6) {
    display: none;
  }

  .menu__nav .menu__separator {
    display: block;
    width: 2px;
    height: 30px;
    background: $dark2;
    background: black;
  }

  .menu__nav .menu__link-actions,
  .menu__nav .menu__link-actions a {
    margin: 0;
    align-items: center;
  }

  .menu__nav .menu__logo,
  .menu__nav .menu__company-info,
  .menu__nav .menu__link-actions .download-app {
    display: none;
  }

  .menu__nav .menu__link-actions {
    gap: 30px;
  }

  .menu__nav .menu__link-actions a {
    font-size: 14px;
    line-height: 18px;
  }

  .menu__button-toggle {
    display: none;
  }
}

@media (min-width: 1103px) {
  .header__menu {
    max-width: 1160px;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1280px) {
  .menu__navigation {
    gap: 30px;
  }
  .menu__nav nav ul {
    gap: 20px;
  }

  .menu__navigation .menu__logo {
    width: 160px;
    margin-right: 60px;
  }
}
