/* INICIO TITULO PERGUNTAS FREQUENTES */
.faq-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 43px;
}

.faq-container .faq-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  margin-top: 75px;
}

.faq-container .faq-description {
  color: #21232f;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 20px;
}

/* TERMINO TITULO PERGUNTAS FREQUENTES */

/* INICIO ITENS PERGUNTAS FREQUENTES */

.faq-items {
  margin-top: 80px;
}

.faq-item {
  display: flex;
  padding: 10px 0 25px;
  border-bottom: 1px solid #eaecf0;
  justify-content: space-between;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-item .faq__question {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.faq-item .faq__question h5 {
  color: #101828;
  font-size: 16px;
  font-weight: 700;
}

.faq-item .faq__question p {
  color: #475467;
  font-size: 16px;
  margin-top: 8px;
  line-height: 24px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.faq-item .faq-btn-toggle {
  background: none;
  border-radius: 100%;
  padding: 1px 4px;
  border: 2px solid #98a2b3;
  cursor: pointer;
  margin-left: 24px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-item .faq-btn-toggle:after {
  content: "\002B";
  color: #98a2b3;
  font-weight: bold;
}

.faq-item .active:after {
  content: "\2212";
}

/* FIM ITENS PERGUNTAS FREQUENTES */

/* INICIO SEÇÃO AINDA TEM DÚVIDAS*/

.still-doubts {
  background: #f9fafb;
  border-radius: 16px;
  margin-top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 32px 0;
}

.doubts__image .avatar-1 {
  margin-right: -20px;
  width: 86px;
}

.doubts__image .avatar-2 {
  position: relative;
  z-index: 1;
  width: 96px;
}

.doubts__image .avatar-3 {
  width: 86px;

  margin-left: -20px;
}

.still-doubts .doubts__title {
  color: #101828;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-top: 32px;
}

.still-doubts .doubts__description {
  color: #475467;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.still-doubts .doubts__button {
  border-radius: 30px;
  border: 1px solid var(--pink2);
  background: var(--pink2);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 12px 20px;
  color: white;
  font-size: 16px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  max-width: 179px;
  margin-top: 32px;
  text-decoration: none;
}

/* TERMINO SECAO AINDA HA DUVIDAS */

@media (min-width: 680px) {
  .faq-container .faq-title {
    text-align: center; 
  }

  .faq-container .faq-description {
    text-align: center;
  }

  .faq-item .faq__question h5 { 
    align-self: flex-start;
  }
}

.faq__container {
  display: flex;
  flex-direction: column;

  .faq__block {
    padding: 40px 0
  }

}


@media (min-width: 1024px) {
  body {
    background: white !important;
  }

  main.help-page {
    display: grid;
    grid-template-columns: auto 900px auto;
  }

  main.help-page section {
    grid-column: 2/3;
  }

  .faq-container {
    margin: 0 auto;
  }

  .faq-item {
    padding: 24px 0px;
    border-bottom: 1px solid #eaecf0;
  }

  .faq-item .faq__question p {
    margin-top: 0px;
  }

  .faq-item .faq__answer {
    align-self: center;
  }
}

@media (min-width: 1280px) {
  main.help-page {
    grid-template-columns: auto 1060px auto;
  }
}
@media(max-width:978px){
  .faq__question {
    align-items: flex-start !important;
  }
  .faq-item .faq__question{
    max-width: 230px;
  }
}