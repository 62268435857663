.search-submit {
    position: absolute;
    border: none;
    top: 0;
    right: 0;
}

.search-form {
    label {
        width: 100%;
    }
}

.buying-content {
    margin-top: 53px;
    padding: 0 27px;
    margin-bottom: 70px;
    .search-stores{
        display: grid;
        justify-content: flex-start;
        
    }
    
    h2 {
        br{
            display: none;
        }
        .buying-content h2 {
            text-align: center;
            padding: 0 10px;
        }
    }
    @media(min-width:1024px){
        h2{
            font-size: 38px;
            text-align: left !important;
            line-height: normal;
            br{
                display: block;
            }
        }
    }
}

.buying-content__search {
    position: relative;
    width: 100%;
    max-width: 600px;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    margin: 27px auto 0;
    box-sizing: border-box;

    input {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 22px 26px;
        border: none;
        border-radius: 100px;
        font-family: $fontText;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #000;
        background-color: #fff;
        box-sizing: border-box;
        &::placeholder{
            color: #000;
        }
    }

    img {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 85px;
    }
}