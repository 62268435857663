footer {
  color: #fff;
  margin-top: auto;
}

.footer__info {
  background: #000;
  padding: 43px 64px;
}

.footer__logo {
  display: flex;
  align-items: center;
  margin-bottom: 76px;
  gap: 10px;
}

.footer__logo span {
  font-family: var(--font-text);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  max-width: 158px;
}

.footer__links {
  display: flex;
  flex-direction: column; 
  margin-bottom: 20px;
}
.f-titles {
  color: #fff;
  font-size: 20px;
  font-family: var(--font-text);
  font-weight: 600;
  line-height: 120%;
}
.links__section-2{
  margin-bottom: 20px;
}
.links__section-1,
.links__section-2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  a{
    color: #fff;
    text-decoration: none;
  }
}
.links__featured-stores {
  display: none;
}

.footer__action-links,
.footer__company-infos {
  display: block;
}

.action-links__download-apps,
.action-links__app-store,
.action-links__google-store {
  display: none;
}

.footer__action-links {
  order: 3;
  max-width: 290px;
}

.links-action__reclame-aqui {
  background: white;
  display: flex;
  justify-content: center;
  padding: 13px 0;
  border-radius: 4px;
}

.footer__company-infos {
  display: flex;
  flex-direction: column;
}

.company-infos__social-medias {
  margin-bottom: 28px;
  display: flex;
  gap: 24px;
}

.company-infos__social-medias a {
  text-decoration: none;
}

.company-infos__company-name p {
  margin: 0;
}

.company-infos__company-name p:last-child {
  margin-bottom: 33px;
}

.footer__made-by {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 15px 0;
}

.footer__made-by span {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--black);
}

@media (min-width: 1024px) {
  .footer__links {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
  }

  .links__section-1,
  .links__section-2,
  .links__featured-stores {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .links__section-1 a,
  .links__section-2 a,
  .links__featured-stores a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-family: var(--opens-sans);
  }

  .footer__logo span {
    max-width: initial;
  }

  .action-links__download-apps,
  .action-links__app-store,
  .action-links__google-store {
    display: block;
  }
  

  .footer__company-infos {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-column: 1/5;
    grid-row: 2/3;
  }

  .footer__action-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
    grid-column: 4/5;
  }

  .links-action__reclame-aqui {
    background: transparent;
    display: block;
    padding: 0px;
    border-radius: 4px;
  }

  .grid-3 {
    display: grid !important;
    grid-template-columns: 1fr minmax(900px, 1060px) 1fr;
  }

  .grid-column-2 {
    grid-column: 2/4;
  }

  .grid-column-3 {
    grid-column: 2/3;
  }
}
.sephora-banner {
  z-index: 999;
  position: relative;
  img {
    width: 100%;
    display: block;
  }
}
@media(max-width:978px){
  .links__section-1 a, .links__section-2 a {
    color: #fff;
    text-decoration: none;
    padding: 5px 80px 5px 0;
    min-width: 200px;
  }
}