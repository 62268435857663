.choose_category {
  width: 100%;
  padding: 0px 43px;
  box-sizing: border-box;
  position: relative;
  margin-top: 50px;
  grid-template-columns: 1fr 1080px 1fr;
  display: grid;

  @media(min-width:1024px) {
    .grid-column-2 {
      grid-column: 2 !important;
    }
  }
}

.featured-stores {
  grid-template-columns: 1fr 1080px 1fr;
  display: grid;
  position: relative;
  

  @media(min-width:1024px) {
    .grid-column-2 {
      grid-column: 2 !important;
    }
  }
}

.choose_category__title {
  font-size: 48px;
  font-weight: 600;
}

.choose_category_slides {
  position: relative;
  margin-top: 100px;
  display: flex;
}

.choose_category_slides .layer {
  position: absolute;
  inset: 0;
  z-index: 999;
  background-image: linear-gradient(to left,
      rgba(255, 255, 255, 1) 7%,
      rgba(255, 255, 255, 0) 30%);
}

.choose_category_slides .slick-slide {
  margin-right: 15px;
}

.featured-stores {
  width: 100%;
  padding: 0px 0 0 43px;
  box-sizing: border-box;
  position: relative;
  margin-top: 80px;
}

.featured-stores__title {
  width: 100%;
  width: 187px;
}

.featured-stores__container {
  position: relative;
  margin-top: 21px;
  margin-bottom: 42px;

  .store__slide {
    .store__name {
      background: linear-gradient(180deg,
          rgba(0, 0, 0, 0) 19.27%,
          rgba(0, 0, 0, 0.6) 70.83%,
          #00062c 100%);
      height: 100%;
      align-items: flex-end;
      padding-bottom: 18px;
      text-align: center;
      color: #fff;
      justify-content: center;

      span {
        padding: 0;
        max-width: 130px;
      }
    }
  }
}

.featured-stores__container .layer {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 9;
  width: 14%;
  right: 0;
  background-image: linear-gradient(to left,
      rgb(255, 255, 255) 7%,
      rgba(255, 255, 255, 0) 90%);
}

.featured-stores__slides {
  .slick-track {
    // left: 3px !important;
  }

  .layer {
    z-index: 9;
  }

  .slick-slide {
    margin-right: 15px;
  }

  .store__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 270px;
    
    .store__slide {
      border: none;
      transition: 0.1s;
      position: relative;
      background-size: cover;
      background-position: center center;
      width: 270px;
      height: 270px;
      border-radius: 8px;
      margin-right: 0;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s !important;
        height: 100%;
        width: 100%;
      }

      &:hover {
        border: none !important;
        outline: none;

        &::before {
          transition: 0.2s !important;
          content: "";
          height: 100%;
          width: 100%;
          background-color: rgba(217, 56, 235, 0.2);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
      }

      

      img {
        z-index: 2;
        max-width: 200px;
        width: 200px;
        bottom: 0;
      }
    }

    .store__name {
      z-index: 2;
      margin-top: 10px;

      span {
        font-family: var(--fontText);
        font-size: 18px;
        line-height: auto;
        font-weight: 600;
        color: #010101;
        padding-left: 5px;
      }
    }
  }
}

.slide-navigation-stores {
  display: block;
  padding-right: 43px;

  ul {
    margin-bottom: 0 !important;
  }
}

.store__slide {
  min-height: 186px !important;
  min-width: 173px;
  background-size: cover;
  //display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  flex-direction: column;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  img {
    width: 10.7375rem;
    flex-shrink: 0;
    position: relative;
  }

  .store__name {
    justify-self: flex-end;
    width: 100%;
    background-color: #fff;
    color: $dark1;
    display: flex;
    bottom: 0px;
    position: absolute;
    height: 50px;
    align-items: center;

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      padding-left: 24px;
      font-family: $fontText;
    }
  }
}

.slide-navigation-category,
.slide-navigation-comment,
.slide-navigation-stores {
  position: relative;
  margin-top: 76px;
}

.dots-navigation,
.arrows-navigation {
  display: flex;
  justify-content: center;
  position: relative;

  @media (min-width: 1024px) {
    width: 100px;
  }
}

.dots-navigation {
  @media (min-width: 1024px) {
    display: flex;
  }
}

.arrows-navigation .arrow.slick-disabled {
  cursor: not-allowed;

  svg path {
    stroke: #cccdce;
  }
}

.arrow {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: 0;
}

.arrow.disabled {
  cursor: initial;
}

.arrow.disabled svg path {
  stroke: #cccdce;
}

.prevArrow {
  transform: rotate(180deg);
  left: 0;
}

.nextArrow {
  right: 0px;

  @media (min-width: 1024px) {
    right: unset;
  }
}

.dots-navigator {
  display: flex !important;
  justify-content: space-around;
  width: 40%;

  @media (min-width: 1024px) {
    display: none !important;
  }
}

.slide-navigation-comment {
  .dots-navigation .dots-navigator {
    display: flex !important;
    justify-content: space-around;
    width: 40%;
  }

  @media (min-width: 1024px) {
    .dots-navigation .dots-navigator {
      width: 100%;
    }
  }
}

.dots-navigatorul {
  display: flex;
  justify-content: space-around;
}

.dots-navigator li button {
  display: none;
}

.dots-navigator li::marker {
  content: url(../../src/images/not-selected-gray.svg);
}

.dots-navigator li.slick-active::marker {
  content: url(../../src/images/selected-gray.svg);
}

.dots-class {
  position: absolute;
  top: 38%;
  right: 0;
  padding-right: 23px;
}

.dots-class li button {
  display: none;
}

.slider .dots-class li::marker {
  content: url(../../src/images/not-selected-pink.svg);
}

.dots-class li.slick-active::marker {
  content: url(../../src/images/selected-pink.svg);
}

@media (min-width: 1024px) {
  .featured-stores__title {
    margin-bottom: 77px;

    a {
      min-width: 192px;
      min-height: 42px;
      font-weight: 600;
      border-radius: 40px;
      background-color: $dark1;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      text-decoration: none;
      margin-left: 112px;

      &:hover {
        color: #fff;
      }
    }
  }
}

@media (max-width: 1024px) {
  .featured-stores {
    padding-left: 20px !important;
    grid-template-columns: 20px 1fr 20px;
    .grid-column-2{
      grid-column: 1/3;
      .arrows-navigation{
        margin-top: 60px;
      }
    }
  }
  .area-wrapper{
    .slide-areas-arrows{
      margin-top: 60px;
    }
  }
  .choose_category {
    display: none;
  }

  .featured-stores__title {
    a {
      display: none;
    }
  }
}