@media (min-width: 1024px) {
  .pagaleve-number__results {
    grid-template-columns: repeat(5, 150px);
    display: grid;
    row-gap: 60px;
    // column-gap: 16px;
    grid-template-rows: repeat(3, auto);
    margin-top: 68px;
    justify-content: space-between;
    place-items: center;
  }
}
