.stores__container {
  display: grid;
  grid-template-columns: auto 1160px auto;



  .stores-filters__container {
    grid-column: 2/3;
    overflow-y: hidden;
    grid-template-columns: 1fr 1060px 1fr;

    .stores-filters__wrapper {
      width: 100%;
      grid-column: 1/4;
      padding-bottom: 40px;
    }

    .filters__info {
      max-height: 250px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h1 {
        font-family: $fontText;
        font-size: 40px;
        font-weight: 500;
        color: $dark1;
        line-height: normal;
      }

      img {
        top: -40px;
        position: relative;
      }

      p {
        font-family: $fontText;
        font-size: 22px;
        font-weight: 500;
        color: $dark1;
        line-height: normal;
      }
    }

    .filters__block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 50px 1fr;
      row-gap: 20px;
      padding-bottom: 40px;

      .filter__input {
        border-bottom: 1px solid $gray;
        width: 90%;
        position: relative;
        height: 40px;

        &::before {
          content: url("../../src/images/ico-search.svg");
          position: absolute;
          bottom: 9px;
          left: 0px;
          z-index: 99;
        }

        input {
          color: $pink;
          font-size: 20px;
          text-align: left;
          width: 100%;
          font-family: $fontText;
          border: none;
          position: relative;
          padding-bottom: 8px;
          text-indent: 25px;

          &:focus {
            outline: none;
          }

          &::placeholder {
            opacity: 1;
          }
        }
      }

      .filter__select {
        select {
          min-height: 45px;
          font-size: 18px;
          width: 320px;
          height: 48px;
          color: #8c8c8c;
          font-weight: 500;
          opacity: 1 !important;
          padding: 12px 0 12px 20px;
          border-radius: 8px;
          border: 1px solid #a5a5a5;
          background-color: #fff;
        }
      }

      .filter__tags {
        display: grid;
        grid-template-columns: repeat(7, 150px);
        grid-template-rows: auto;
        grid-column: 1/3;
        width: 100%;
        justify-content: space-between;
        row-gap: 10px;

        p {
          grid-area: 1 / span 7;
          font-family: $fontText;
          font-size: 14px;
          font-weight: 500;
          color: #010101;
          margin-bottom: 5px;
        }

        .filter-tag__block {
          cursor: pointer;
          background-color: #fff;
          border: 1px solid #a5a5a5;
          border-radius: 16px;
          width: 150px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $fontText;
          color: #8c8c8c;
          font-weight: 500;
          margin-right: 10px;

          &.filter-tag__block.active {
            background-color: $green;
            border-color: $green;
            color: $black;
          }

          &:hover {
            background-color: $green;
            border-color: $green;
            color: $black;
            cursor: pointer !important;
          }
          
          input {
            position: absolute;
            display: none;
            color: #fff !important;
          }
          label{
            cursor: pointer !important;
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .filtered-stores__container {
      gap: 30px;
      padding: 0 0 30px 0px;
      // justify-content: center;

      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(auto, 260px));

      .store__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 270px;

        .store__slide {
          border: none;
          transition: 0.1s;
          position: relative;
          background-size: cover;
          background-position: center center;
          width: 270px;
          height: 270px;
          border-radius: 8px;
          margin-right: 0;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transition: 0.2s !important;
            height: 100%;
            width: 100%;
          }

          &:hover {
            border: none !important;
            outline: none;

            &::before {
              transition: 0.2s !important;
              content: "";
              height: 100%;
              width: 100%;
              background-color: rgba(217, 56, 235, 0.2);
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
            }
          }

          .tag {
            min-width: 95px;
            height: 20px;
            box-sizing: border-box;
            padding: 6px 16px;
            text-align: center;
            background-color: $green;
            position: absolute;
            bottom: 10px;
            right: 20px;
            color: $black;
            font-size: 14px;
            font-family: $fontText;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            border-radius: 20px;
            z-index: 2;
          }

          img {
            z-index: 2;
            max-width: 200px;
            bottom: 0;
            width: 200px;
          }
        }

        .store__name {
          z-index: 2;
          margin-top: 10px;

          span {
            font-family: var(--fontText);
            font-size: 18px;
            line-height: auto;
            font-weight: 600;
            color: #010101;
            padding-left: 5px;
          }
        }
      }
    }

    .disabled {
      display: none;
    }
  }
}

body {
  .featured-stores {
    .store__slide {
      max-width: 150px;
    }
  }

  .featured-stores-alt,
  .stores-latest__container {
    grid-column: 2/3;
    background-color: rgba(108, 227, 189, 0.18);
    padding: 80px 0;

    .featured-stores__title {
      font-size: 40px;
      font-weight: 500 !important;
      font-family: $fontText;
      color: $black;

      img {
        margin-right: 12px;
      }
    }

    .d-flex-none {
      display: block !important;
    }

    .featured-stores__container-alt {
      display: flex;
      flex-wrap: wrap;
      gap: 28px;
      padding: 0 0 30px 10px !important;
      overflow-x: hidden;

      .featured-stores__slides .slick-slide {
        margin: 0 28px 0 0;
      }

      .slick-track {
        padding: 20px 4px;
        // left: 0 !important;
      }

      .store__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 270px;

        .store__slide {
          border: none;
          transition: 0.1s;
          position: relative;
          background-size: cover;
          background-position: center center;
          width: 270px;
          height: 270px;
          border-radius: 8px;
          margin-right: 0;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transition: 0.2s !important;
            height: 100%;
            width: 100%;
          }

          &:hover {
            border: none !important;
            outline: none;

            &::before {
              transition: 0.2s !important;
              content: "";
              height: 100%;
              width: 100%;
              background-color: rgba(217, 56, 235, 0.2);
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
            }
          }



          img {
            z-index: 2;
            max-width: 200px;
            width: 200px;
            bottom: 0;
          }
        }

        .store__name {
          z-index: 2;
          margin-top: 10px;

          span {
            font-family: var(--fontText);
            font-size: 18px;
            line-height: auto;
            font-weight: 600;
            color: #010101;
            padding-left: 5px;
          }
        }
      }
    }
  }

  .stores-latest__container {
    background-color: rgba(227, 106, 241, 0.17);

    .featured-stores__slides2 {
      .store__wrapper {
        margin-right: 30px;
      }
    }
  }
}

.stores-display__container {
  .grid-column-3 {
    display: grid;
    grid-template-columns: 1fr minmax(900px, 1060px) 1fr;

    .grid-2 {
      grid-column: 2/3;

      .stores-display__heading {
        margin: 90px 0 50px;

        h2 {
          font-size: 48px;
          font-weight: 500;

          @media(max-width: 978px) {
            font-size: calc(1.325rem + 0.9vw) !important;
          }

          img {
            margin-right: 10px;
          }
        }
      }

      .stores-display__row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        justify-content: center;
        gap: 30px;

        .store__wrapper {
          display: flex;
          flex-direction: column;
          max-width: 270px;

          .store__slide {
            border: none;
            transition: 0.1s;
            position: relative;
            background-size: cover;
            background-position: center center;
            width: 270px;
            height: 270px;
            border-radius: 8px;
            margin-right: 0;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              transition: 0.2s !important;
              height: 100%;
              width: 100%;
            }

            &:hover {
              border: none !important;
              outline: none;

              &::before {
                transition: 0.2s !important;
                content: "";
                height: 100%;
                width: 100%;
                background-color: rgba(217, 56, 235, 0.2);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
              }
            }



            img {
              z-index: 2;
              max-width: 200px;
              width: 200px;
              bottom: 0;
            }
          }

          .store__name {
            z-index: 2;
            margin-top: 10px;

            span {
              font-family: var(--fontText);
              font-size: 18px;
              line-height: auto;
              font-weight: 600;
              color: #010101;
              padding-left: 5px;
            }
          }
        }
      }

      .footer-stores {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 90px;

        button {
          background: #010101;
          border-radius: 8px;
          width: 133px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 14px;
          border: none;
        }
      }
    }
  }
}

#main-stores-container {
  @media (min-width: 1200px) {
    .grid-column-3 {
      grid-template-columns: 1fr 1170px 1fr;
    }
  }
}

@media (max-width: 1200px) {

  .featured-stores-alt,
  .stores-latest__container {
    padding: 30px 0 !important;
  }

  .stores-display__heading {
    padding: 50px 0;
  }

  .stores__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    .stores-filters__wrapper {
      padding: 0 20px 40px;
    }

    .stores-filters__container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .filters__info {
        flex-direction: column;
        max-height: unset;

        h1 {
          z-index: 9;
          top: 120px;
          position: relative;
        }

        img {
          top: -232px;
          position: relative;
        }

        p {
          position: relative;
          top: -110px;
          text-align: center;

          br {
            display: none;
          }
        }
      }

      .filters__block {
        gap: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -40px;

        .filter__select {
          select {
            width: 280px;
          }
        }

        .filter__tags {
          place-items: center;
          max-width: 350px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          p {
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
          }

          .filter-tag__block {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .featured-stores-alt,
  .stores-latest__container {
    .featured-stores__title {
      font-size: calc(1.325rem + 0.9vw) !important;
      padding-left: 20px;
      display: flex;
      width: 100%;
    }
  }

  .stores-display__container {
    .grid-column-3 {
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;

      .grid-2 {
        .stores-display__row {
          a {
            margin-bottom: 20px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(auto, 260px));
          }
        }
      }
    }
  }

  .slide-navigation-stores2,
  .slide-navigation-stores {
    margin-top: 36px !important;
    width: 100%;
    padding: 0 20px;

    .arrows-navigation {
      display: flex;
      justify-content: space-between;
    }
  }
}

.stores-result__container {
  display: grid;
  grid-template-columns: auto 1060px auto;

  .stores-result__wrapper {
    grid-column-start: 2;
    display: flex;
    gap: 30px;
    justify-content: flex-start;
    padding: 30px 0;
    flex-wrap: wrap;
  }

  .store__slide {
    grid-column-start: 2;
    max-width: 173px;
  }
}

#filtered-posts {
  position: relative;
}

.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader-wrapper img{
  width: 80px;
  height: 80px;
}

.load-search {
  display: none;
  //width: 15%;
}


.store__tags {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0px;
  right: 10px;
  width: 92%;
  flex-direction: row;
  align-items: flex-end;

  gap: 5px;

  .tag {
    min-width: 120px !important;
    height: 20px;
    box-sizing: border-box;
    padding: 6px 16px;
    text-align: center;
    background-color: $green;
    position: relative !important;
    bottom: 10px !important;
    right: 0 !important;
    color: $black;
    font-size: 14px;
    font-family: $fontText;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 20px;
    z-index: 2;
  }
}
.banner__container{
  a{
    grid-column: 2/3;
  
    display: grid;
    .banner__block{
      grid-column: 2/4;
      display: grid;
    }
    img{
      max-width: 100%;
      height: auto;
    }
  }
}