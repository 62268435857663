.sitemap__container {
    display: grid;
    grid-template-columns: auto 1160px auto;

    .sitemap__wrapper {
        grid-column: 2/3;
        overflow-y: hidden;
        grid-template-columns: 1fr 1060px 1fr;

        .sitemap__heading {
            padding: 40px 0;

            h2 {
                color: #333;
                font-family: var(--font-text);
                font-weight: 600;
                font-size: 48px;
            }
        }

        .sitemap__content {
            display: flex;
            flex-direction: column;

            .stores-filters__container {
                grid-column: 2/3;
                overflow-y: hidden;
                grid-template-columns: 1fr 1060px 1fr;
                display: grid;

                .stores-filters__wrapper {
                    width: 100%;
                    grid-column: 1 / 4;
                    padding-bottom: 40px;

                    .filters__info {
                        max-height: 250px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        h2 {
                            font-family: "Quicksand", sans-serif;
                            font-size: 40px;
                            font-weight: 500;
                            color: rgb(33, 35, 47);
                            line-height: normal;
                        }

                        img {
                            top: -40px;
                            position: relative;
                        }

                        p {
                            font-family: "Quicksand", sans-serif;
                            font-size: 22px;
                            font-weight: 500;
                            color: rgb(33, 35, 47);
                            line-height: normal;
                        }
                    }

                    .filters__block {
                        overflow-y: hidden;
                        grid-template-columns: 1fr 1060px 1fr;

                        .filter__input {
                            margin-top: 20px;
                            border-bottom: 1px solid #333;
                            position: relative;

                            &::before {
                                content: url("../../src/images/ico-search.svg");
                                position: absolute;
                                bottom: 9px;
                                left: 0px;
                                z-index: 99;
                            }

                            input {
                                color: rgb(254, 53, 241);
                                font-size: 20px;
                                text-align: left;
                                width: 100%;
                                font-family: "Quicksand", sans-serif;
                                border: medium;
                                position: relative;
                                padding-bottom: 8px;
                                text-indent: 25px;

                                &:focus {
                                    outline: none;
                                    border: none;
                                }

                            }
                        }
                    }

                    .filtered-stores__container {
                        gap: 30px;
                        padding: 40px 0px 30px;
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(auto, 260px));

                        .store__wrapper {
                            display: flex;
                            flex-direction: column;
                            max-width: 270px;

                            .store__slide {
                                border: medium;
                                transition: 0.1s;
                                position: relative;
                                background-size: cover;
                                background-position: center center;
                                width: 270px;
                                height: 270px;
                                border-radius: 8px;
                                margin-right: 0px;

                                img {
                                    width: 90%;
                                    position: relative;
                                    margin-bottom: 5px;
                                    padding-left: 10px;
                                }

                                .store__tags {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    transition: 0.2s !important;
                                    height: 100%;
                                    width: 92%;

                                    .tag {
                                        top: -10px;
                                        left: 10px;
                                        position: relative;
                                    }
                                }

                                &::before{
                                    transition: 0.2s !important;
                                    content: "";
                                    height: 100%;
                                    width: 100%;
                                    background-color: transparent;
                                    position: absolute;
                                    left: 0px;
                                    top: 0px;
                                    z-index: 1;
                                }
                                &:hover {
                                    &::before {
                                        transition: 0.2s !important;
                                        content: "";
                                        height: 100%;
                                        width: 100%;
                                        background-color: rgba(217, 56, 235, 0.2);
                                        position: absolute;
                                        left: 0px;
                                        top: 0px;
                                        z-index: 1;
                                    }
                                }
                            }
                        }

                        .store__name {
                            z-index: 2;
                            margin-top: 10px;

                            span {
                                margin-top: 60px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

        .sitemap__block {
            margin-bottom: 20px;

            h2 {
                font-size: 32px;
                margin-bottom: 10px;
            }

            ul {
                list-style-type: disc;

                li {
                    margin-bottom: 5px;
                    padding-left: 10px;

                    a {
                        color: var(--pink);
                        text-decoration: none;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}


@media(max-width:978px) {
    .sitemap__container .sitemap__wrapper .sitemap__heading h1 {
        text-align: center;
    }

    .sitemap__container .sitemap__wrapper .sitemap__content .stores-filters__container .stores-filters__wrapper {
        grid-column: 1/3;
    }

    .sitemap__container .sitemap__wrapper .sitemap__content .stores-filters__container,
    .sitemap__container {
        grid-template-columns: 0 1fr 0;
        padding: 0 20px;
    }

    .sitemap__container .sitemap__wrapper .sitemap__content .stores-filters__container .stores-filters__wrapper .filters__info {
        flex-direction: column;
    }

    .sitemap__container .sitemap__wrapper .sitemap__content .stores-filters__container .stores-filters__wrapper .filters__info img {
        display: none;
    }

    .sitemap__container .sitemap__wrapper .sitemap__content .stores-filters__container .stores-filters__wrapper .filters__info p {
        text-align: center;
        margin-top: 12px;
    }

    .sitemap__container .sitemap__wrapper .sitemap__content .stores-filters__container .stores-filters__wrapper .filters__info p br {
        display: none;
    }
}