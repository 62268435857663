* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $fontText;
}
.title {

  font-family: $fontText
}
html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.no-margin {
  margin: 0;
}

.hidden {
  overflow: hidden;
}

.pl-0{
  padding-left: 0;
}
main {
  width: 100%;
  transition: all 0.2s linear;
  filter: blur(0px);
  padding: 0 0px 0px;
  background: #fff;
}

@media(max-width:978px) {

  main {
    width: 100%;
    transition: all 0.2s linear;
    filter: blur(0px);
    padding: 0px 0px;
    background: #f9fafb;
  }
}
@media (min-width: 1024px) {
  .title {
    font-family: var(--font-text);
    font-weight: 600 !important;
    font-size: 48px !important;
    line-height: 53px;
    color: var(--black);
    width: unset;
  }

  .grid-3 {
    display: grid !important;
    grid-template-columns: 1fr minmax(900px, 1060px) 1fr;
  }

  .grid-column-2 {
    grid-column: 2/4;
  }

  .grid-column-3 {
    grid-column: 2/3;
  }
}
