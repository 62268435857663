.blog-top-news {
  background: url("../../src/images/image-bg-green-arrow.png"),
    url("../../src/images/background-blog-top.png");
  background-size: 40% 90%, 100% 900px;
  background-repeat: no-repeat, no-repeat;
  background-position: -200px 0, 0px 0px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(1000px, 1060px) 1fr;
  justify-items: center;

  .news-description {
    width: 100%;
    color: #475467;
    font-family: var(--font-inter);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin: 56px auto 0;
    padding: 0 43px 32px;
    position: relative;
    border-bottom: 1px solid #eaecf0;
    max-width: 720px;
    box-sizing: border-box;
    grid-column: 2/3;
  }
}

.news-letter {
  grid-column: 2/3;
  margin-top: 120px;
  .back-button{
    color: $pink;
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 20px;
    font-weight: bold;
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    transition: .1s;
    &:hover{
      color: #fff;
      background-color: $pink;
      border: 1px solid #fff;
    }
  }
}

.news-letter .categories-tags {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.blog-articles {
  display: flex;
  flex-direction: column;
  align-items: center;
  .blog-article{
    @media(min-width:978px){
      min-height: 550px;
    }
  }
}

.blog-articles .articles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 340px));
  grid-template-rows: auto auto;
  gap: 20px;
  justify-content: center;
  max-width: 1060px;
  margin: 0 auto;
}

.blog-top-news .news-letter__title {
  color: white;
  text-align: center;
  font-size: 48px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.blog-top-news .news-letter__description {
  color: #eaecf0;
  text-align: center;
  font-size: 20px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  max-width: 768px;
  margin-top: 24px;
}

.blog-top-news .sign-in-news {
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-top: 40px;
}

.blog-top-news .sign-in-input {
  border-radius: 32px;
  border: 2px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  background: transparent;
  padding: 12px 14px;
  width: 360px;
  color: white;
}

.blog-top-news .sign-in-input:focus {
  outline: 1px white;
}

.blog-top-news .sign-in-input::placeholder {
  color: white;
}

.blog-top-news .sing-in-button {
  border-radius: 30px;
  border: 1px solid #fff;
  background: #fff;
  color: #da38eb;
  padding: 12px 20px 10px;
  font-size: 16px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.blog-news {
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fresh-news {
  grid-column: 2/3;
  width: 100%;
  height: 607px;
  margin-top: 70px;
  border-radius: 32px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  span{
    position: relative !important;
    bottom: unset !important;
    left: unset !important;
    top: unset !important;
    right: unset !important;
    padding-left: 30px;
    &:last-child{
      padding-bottom: 40px;
    }
  }

  &::before {
    content: '';
    background-color: rgba(0, 0, 0, .5);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
  }
  @media(max-width:978px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    span{
      position: relative !important;
      top: unset !important;
      bottom: unset !important;
      right: unset !important;
      left: 0px !important;
    }
  }
}

.fresh-news .fresh-news-label {
  position: absolute;
  top: 20px;
  right: 30px;
  left: 30px;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  z-index: 3;

  @media (min-width: 1024px) {
    left: unset;
  }
}

.fresh-news .fresh-image-label {
  border-radius: 30px;
  border: 1px solid #da38eb;
  background: #da38eb;
  padding: 8px 14px 9px;
  color: white;
  font-size: 16px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  z-index: 3;

  @media (min-width: 1024px) {
    padding: 14px 20px 12px;
  }
}

.fresh-news .fresh-image-signature {
  position: absolute;
  bottom: 179px;
  z-index: 3;

  left: 90px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.fresh-news .fresh-image-title {
  position: absolute;
  bottom: 348px;
  left: 90px;
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  z-index: 3;

}

.fresh-news .fresh-image-description {
  position: absolute;
  bottom: 67px;
  left: 90px;
  color: #fff;
  font-size: 16px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 880px;
  z-index: 3;
  p{
    margin-bottom: 40px;
    padding-left: 20px;
  }
}

.main-blog-page {
  width: 100%;
  margin: 0 auto;
  gap: 20px;
  margin-top: 141px;
}

.blog-article .attachment-post-thumbnail {
  max-height: 264px;
  max-width: 340px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.main-blog-page .blog-article .article-image {
  position: relative;
  display: none;
}

.main-blog-page .blog-article .image-label {
  position: absolute;
  bottom: 15px;
  right: 20px;
  border-radius: 30px;
  border: 1px solid #da38eb;
  background: #da38eb;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  font-size: 16px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 14px 20px 12px;
}

.main-blog-page .blog-article .article-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main-blog-page .blog-article .article-signature {
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-top: 27px;
}

.main-blog-page .blog-article .article-title {
  color: var(--gray-900, #101828);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  display: block;
}

.main-blog-page .blog-article .article-description {
  color: #475467;
  font-size: 16px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: block;
}

.blog-article .article-labels .article-hot-label {
  border-radius: 16px;
  background: #fdf2fa;
  padding: 10px 16px 8px;
  color: #c11574;
  text-align: center;
  font-size: 14px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.blog-article .article-labels .article-fresh-label {
  border-radius: 16px;
  background: var(--success-50, #ecfdf3);
  color: #027a48;
  text-align: center;
  font-size: 14px;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 16px 8px;
}

.fresh__image {
  width: 100%;
  object-position: center;
  position: absolute;
  bottom: -5px;
  img {
    height: 100%;
    max-width: 100%;
    min-height: 610px;
    bottom: 20px;
  }
}

.blog-news-writer {
  width: 100%;
  padding: 0 43px;
  margin: 32px auto 0px;
  box-sizing: border-box;
  max-width: 720px;
  background: none;

  h1,
  h2 {
    color: #101828;
    font-family: var(--font-inter);
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
  }

  p {
    color: var(--gray3);
    font-family: var(--font-inter);
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
  strong{
    line-height: 42px;
  }

  & p:nth-of-type(1) {
    width: 100%;
    border-bottom: 1px solid #eaecf0;
    padding-bottom: 32px;
    margin-top: 20px;

    @media (min-width: 1024px) {
      margin-top: 64px;
    }
  }

  figure {
    margin: 0 0 84px;

    img {
      width: 100%;
      height: auto;
    }

    figcaption {
      color: var(--gray3);
      font-family: var(--font-inter);
      align-self: flex-start;
      font-size: 14px;
      line-height: 20px;
    }
  }

  blockquote {
    border-left: 2px solid black;
    padding: 10px 16px;
    margin: 0;

    & p:nth-of-type(1) {
      color: #101828;
      font-family: var(--font-inter);
      font-size: 20px;
      font-style: italic;
      font-weight: 500;
      line-height: 30px;
      margin: 0;
      border-bottom: 0;
      padding-bottom: 0;

      @media (min-width: 1024px) {
        margin-top: 0;
      }
    }

    cite {
      font-family: var(--font-inter);
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      font-style: italic;
      color: #101828;
    }
  }

  pre {
    border-left: 2px solid black;
    padding-left: 16px;
    font-family: var(--font-inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #475467;
    padding-top: 32px;
    margin-bottom: 56px;
  }

  ul,
  ol {
    margin-top: 16px;

    li {
      list-style: decimal;
      list-style-position: outside;
      list-style-position: inside;
      color: var(--gray3);
      font-family: var(--font-inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  a {
    color: var(--pink);
    text-decoration: none;
  }

  .conclusion {
    background: var(--background);
    padding: 32px;
    border-radius: 16px;

    h2 {
      margin: 0;
    }

    & p:nth-of-type(1) {
      margin: 0;
      border-bottom: 0;
      padding-bottom: 0;
      margin-top: 16px;
    }
  }
}

.blog-news-writer figure img {
  width: 100%;
}

.blog-news-writer figcaption {
  color: var(--gray3);
  font-family: var(--font-inter);
  align-self: flex-start;
  font-size: 14px;
  line-height: 20px;
}

.blog-news-writer figcaption span {
  text-decoration: underline;
}

/* FIM IMAGEM BLOG */

.blog-news-writer .quotation {
  border-left: 2px solid black;
  padding-left: 16px;
  margin: 32px 0;
}

.blog-news-writer .quotation q {
  color: #101828;
  font-family: var(--font-inter);
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
  line-height: 30px;
}

.blog-news-writer .quotation p {
  color: var(--gray3);
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.blog-news-writer ul {
  margin-top: 16px;
}

.blog-news-writer ul li {
  list-style: decimal;
  list-style-position: inside;
  color: var(--gray3);
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.conclusion {
  margin-top: 32px;
}

.conclusion h2 {
  margin: 0;
}

.blog-news-writer .news-social-media {
  margin: 32px 0px 32px;
  border-top: 1px solid #eaecf0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 1024px) {}
}

.blog-news-writer .news-social-media .labels {
  margin-top: 24px;
  display: flex;
  gap: 10px;
}

.blog-news-writer .news-social-media span {
  padding: 2px 10px;
  font-size: 14px;
  font-family: var(--font-inter);
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  border-radius: 16px;
}

.blog-news-writer .news-social-media span:nth-child(1) {
  color: #107569;
  background: #f0fdf9;
}

.blog-news-writer .news-social-media span:nth-child(2) {
  background: #eef4ff;
  color: #3538cd;
}

.blog-news-writer .news-social-media span:nth-child(3) {
  background: #da38eb29;
  color: #da38eb;
}

.blog-news-writer .news-social-media span:nth-child(4) {
  background: #8080801a;
  color: gray;
}

.blog-news-writer .news-social-media span:nth-child(5) {
  background: #80008024;
  color: purple;
}

.blog-news-writer .news-social-media span:nth-child(6) {
  background: #00807624;
  color: #008076;
}

.blog-news-writer .news-social-media .medias {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.news-social-media .medias button {
  color: #344054;
  font-family: var(--font-inter);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 30px;
  border: 1px solid #d0d5dd;
  background: #fff;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.news-social-media .medias a {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  padding: 10px;
  display: flex;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  text-decoration: none;
}

.news-letter-blog {
  background: #f9fafb;
  display: flex;
  justify-content: center;
  margin-top: 110px;
  padding: 50px 0;
}

.news-letter-blog .content {
  max-width: 690px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news-letter-blog .content p {
  margin-bottom: 0px;
}

.news-letter-heading {
  color: #21232f;
  font-family: var(--font-text);
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.news-letter-description {
  color: #21232f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 18px;
  max-width: 500px;
  text-align: left;
}

.news-letter-form {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  gap: 20px;
}

.news-letter-form .wpcf7-email {
  width: 100%;
  max-width: 516px;
  border-radius: 32px;
  border: 1px solid #475467;
  padding: 12px 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.news-letter-form input::placeholder {
  color: #475467;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.news-letter-form .wpcf7-submit {
  color: #f9fafb;
  cursor: pointer;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 30px;
  border: 1px solid #000;
  background: #000;
  padding: 12px 50px;
  margin-top: 0px;
  align-self: flex-end;
  margin-left: auto;
}

.news-letter-form .form-row {
  display: flex;
  gap: 20px;

  @media(max-width:978px) {
    flex-wrap: wrap;
  }
}

.news-letter-form .wpcf7-acceptance label {
  span {
    a {
      color: var(--pink);
    }
  }

  input {
    margin-left: 10px;
  }
}

.news-letter-form label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 5px;

  input {
    margin-right: 5px;
  }
}

.user-type div,
.privacy-policy,
.privacy-policy div {
  display: flex;
  gap: 15px;
}

.user-type p,
.privacy-policy p,
.privacy-policy a {
  color: #475467;
  font-family: var(--font-mont);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.label-checkbox {
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  color: var(--color-lgray-text);
  margin: 0;
  border-radius: 4px;
}

.label-checkbox input {
  display: none;
}

.checked-img {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #000;
  margin-right: 5px;
  border-radius: 2px;
  border-radius: 3px;
  padding: 3px 5px 1px 1px;
}

input+.checked-img img {
  background-color: white;
  display: none;
  align-items: center;
  justify-content: center;
}

.user-type {
  margin-top: 15px;
}

.privacy-policy {
  margin-top: 20px;
}

.privacy-policy a {
  font-family: var(--font-text);
  color: black;
}

.privacy-policy a:hover {
  color: black;
}

.form-wrapper {
  width: 100%;
}

.blog-news {
  .news-letter {
    display: flex;
    flex-direction: column;
    margin-top: 85px;
    align-items: center;

    span {
      display: grid;
      text-align: center;
      color: #fff;
      font-family: var(--font-inter);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .news-letter__title {
      color: #fff;
      font-family: var(--font-text);
      font-size: 38px;
      font-weight: 700;
      line-height: 42px;
    }

    .news-letter__date {
      margin-top: 40px;
      font-size: 16px;
    }

    .news-letter__description {
      p {
        color: #eaecf0;
        font-family: var(--font-inter);
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 16px;
      }
    }

    .news-info {
      display: flex;
      justify-content: center;
      gap: 40px;
      margin-top: 40px;

      .blog-writer {
        display: flex;
        align-items: center;
        gap: 12px;

        .blog-author {
          display: flex;
          align-items: center;
          gap: 12px;

          .writer-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .writer-name {
              color: #fff;
              font-family: var(--font-inter);
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              margin: 0;
            }

            .news-release {
              color: #fff;
              font-family: var(--font-inter);
              font-size: 16px;
              font-weight: 500 !important;
              line-height: 24px;
              margin: 0;
            }
          }

          img {
            max-width: 52px;
            max-height: 52px;
            border-radius: 100%;
            border: 2px solid #eaecf0;
          }
        }
      }

      .listen-news {
        button {
          border-radius: 30px;
          border: 1px solid #d0d5dd;
          background: #fff;
          display: flex;
          align-items: center;
          gap: 12px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: #fe35f1;
          font-family: var(--font-public);
          font-size: 16px;
          font-weight: 600;
          line-height: 28px;
          padding: 16px 26px;
        }
      }
    }
  }
}

.news-letter-description {
  font-size: 20px;
}

@media (min-width: 1024px) {
  .blog-news-writer {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1400px) {
  .blog-top-news {
    background-size: 30% 100%, 100% 100%;
    background-repeat: no-repeat, no-repeat;
    background-position: -170px 0, 0px -300px;
  }
}

@media (max-width: 978px) {
  .blog-top-news {
    display: flex;
    flex-direction: column;
    padding: 0 28px;

    .news-letter {

      span,
      h2,
      .news-letter__description {
        text-align: left;
      }
    }

    .fresh-news {
      .fresh-news-info {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        gap: 13px;
        position: relative;
        bottom: 20px;
        padding: 0 34px;
        max-height: 500px;
        overflow: hidden;

        .fresh-image-signature {
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          position: relative;
          bottom: unset;
          left: unset;
        }

        .fresh-image-title {
          color: #fff;
          font-size: 30px;
          font-weight: 700;
          line-height: 38px;
          position: relative;
          bottom: unset;
          left: unset;
        }

        .fresh-image-description {
          position: relative;
          color: #fff;
          font-size: 16px;
          font-family: var(--font-inter);
          font-weight: 400;
          line-height: 24px;
          max-width: 880px;
          bottom: unset;
          left: unset;
        }
      }
    }

    .news-info {
      flex-direction: column;

      .listen-news {
        button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  .news-letter-blog {
    background: #f9fafb;
    display: flex;
    justify-content: center;
    margin-top: 110px;
    padding: 50px 0;
  }

  .news-letter-blog .content {
    max-width: 690px;
    margin: 0 auto;
    padding: 0 27px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .news-letter-blog .content p {
    margin-bottom: 0px;
  }

  .news-letter-heading {
    color: #21232f;
    font-family: var(--font-text);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .news-letter-description {
    color: #21232f;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 18px;
    max-width: 500px;
    text-align: left;
  }

  .news-letter-form {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .news-letter-form .wpcf7-email {
    width: 100%;
    max-width: 516px;
    border-radius: 32px;
    border: 1px solid #475467;
    padding: 12px 14px;
    box-sizing: border-box;
  }

  .news-letter-form input::placeholder {
    color: #475467;
    font-family: var(--font-inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .news-letter-form .wpcf7-submit {
    color: #f9fafb !important;
    cursor: pointer;
    font-family: var(--font-inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 30px;
    border: 1px solid #000 !important;
    background: #000 !important;
    padding: 20px 51px;
    margin-top: 40px;
    align-self: flex-end;
    margin-left: auto;
  }

  .user-type div,
  .privacy-policy,
  .privacy-policy div {
    display: flex;
    gap: 15px;
  }

  .user-type p,
  .privacy-policy p,
  .privacy-policy a {
    color: #475467;
    font-family: var(--font-mont);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 214.286% */
  }

  .label-checkbox {
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 160%;
    color: var(--color-lgray-text);
    margin: 0;
    border-radius: 4px;
  }

  .label-checkbox input {
    display: none;
  }

  .checked-img {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border: 1px solid #000;
    margin-right: 5px;
    border-radius: 2px;
    border-radius: 3px;
    padding: 3px 5px 1px 1px;
  }

  input+.checked-img img {
    background-color: white;
    display: none;
    align-items: center;
    justify-content: center;
  }

  .user-type {
    margin-top: 15px;
  }

  .privacy-policy {
    margin-top: 20px;
  }

  .privacy-policy a {
    font-family: var(--font-text);
    color: black;
  }
}

@media(max-width:1024px) {
  .fresh-news .fresh-image-title {
    left: 30px;
    padding-right: 20px;
  }

  .fresh-news .fresh-image-description {
    left: 0px !important;
    padding-right: 30px;
  }

  .blog-articles .articles {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 20px;
  }

  .blog-news .news-letter .news-letter__title {
    text-align: center;
  }

  .blog-top-news .fresh-news .fresh-news-info .fresh-image-description {
    bottom: 0px !important;
    left: 0 !important;
    padding: 0  !important;
    p{
      padding: 0 !important;
    }
  }

  .fresh__image img {
    object-fit: cover;
    object-position: center center;
  }
}