.container-privacy {
  display: grid;
  grid-template-columns: 44px 1fr 44px;

  .content {
    grid-column: 2/3;
    margin-top: 40px;
  }

  h3 {
    margin-bottom: 16px;
  }

  p {
    padding-bottom: 1.5rem;
    line-height: 25px;
    font-family: var(--font-text);
    font-weight: 500;
  }

  ol {
    list-style-position: inside;
    margin-left: 30px;
    margin-bottom: 30px;
  }

  ol li {
    line-height: 25px;
    font-weight: 500;
  }

  ol ol {
    margin-bottom: 0px;
  }

  table {
    margin: 0 0 20px;
    padding: 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #fff;
    border: 1px solid #ececec;
  }

  table tr:nth-child(2n + 1) {
    background-color: #fcfcfc;
  }

  table td {
    border-color: #ececec;
    border-left: none;
    border-style: none solid solid none;
    border-top: medium none;
    border-width: medium 1px 1px medium;
    font-size: 14px;
    padding: 10px;
    font-weight: 400;
    text-align: left;
    line-height: 25px;
  }

  .data-collect thead {
    border-bottom: 3px solid black;
  }

  .data-collect thead th {
    padding: 10px;
    text-align: left;
    font-weight: 600;
    border-left-style: solid;
    border-left-width: 1px;
    border-color: #ececec;
  }

  .data-collect tr {
    vertical-align: baseline;
  }

  a {
    text-decoration: none;
    color: black;
  }

  @media (min-width: 1024px) {
    & {
      grid-template-columns: 1fr minmax(936px, 1060px) 1fr;
    }
  }
}
