.form-description {
  max-width: 610px;
  min-height: 610px;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 53px 33px;
  position: relative;
  z-index: 2;


  h2 {
    text-align: center;
    color: var(--pink);
    font-family: var(--fontText);
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 50px;
  }

  h3 {
    text-align: center;
    color: var(--dark1);
    font-family: var(--fontText);
    font-size: 18px;
    line-height: 25, 2px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .step {
    display: none;
    transition: opacity 0.3s ease-in-out;

    .step__line {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      position: relative;

      label {
        font-size: 18px;
        font-family: var(--fontText);
        color: var(--gray5);
        line-height: 25.2px;
        font-weight: 700;
        pointer-events: none;
        margin-left: 20px;
      }

      input {
        width: 100%;
        border: 1px solid #f3f3f3;
        background-color: #fff;
        border-radius: 100px;
        max-height: 52px;
        min-height: 52px;
        height: 52px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        font-family: var(--fontText);
        color: var(--gray5);
        text-indent: 15px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
      }

      .step__field {
        label {
          font-size: 16px;
          font-family: var(--fontText);
          color: var(--pink);
          line-height: 24px;
          font-weight: 600;
          pointer-events: none;
        }

        input[type="radio"] {
          -webkit-appearance: none;
          appearance: none;
          width: calc(100% + 20px);
          height: 40px !important;
          border-radius: 70px !important;
          margin: calc(0.75em - 11px) 0.25rem 0 0;
          border: 1px solid var(--pink) !important;
          color: var(--pink);
          cursor: pointer;
          background: #fff;
        }

        input[type="radio"]:checked {
          -webkit-appearance: none;
          appearance: none;
          height: 40px !important;
          border-radius: 70px !important;
          vertical-align: top;
          border: 1px solid var(--pink) !important;
          color: #fff;
          background: var(--pink);
        }
      }

      select,
      .select2-selection {
        width: 100%;
        border: 1px solid #f3f3f3 !important;
        background-color: #fff !important;
        border-radius: 100px !important;
        max-height: 52px !important;
        min-height: 52px !important;
        text-indent: 10px;
        height: 52px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px !important;
        font-weight: 500 !important;
        font-family: var(--fontText) !important;
        color: var(--gray5) !important;
        margin-top: 70px;
        // margin-bottom: 73px !important;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .step__next {
      background-color: var(--black);
      box-sizing: border-box;
      width: 207px;
      height: 48px;
      border-radius: 30px;
      padding: 12px 54px;
      color: var(--white);
      font-size: 16px;
      font-weight: 600;
      border: none;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      &:disabled {
        background-color: #b4b4b4;
        cursor: not-allowed;
        opacity: 1;
      }
    }

    .step__buttons {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 10px;
      justify-content: space-around;
      margin-top: 70px;

      &:nth-child(1) {
        background-color: red;
      }

      label {
        margin-left: 0 !important;
      }

      .step__line {
        margin-bottom: 0;

        &:nth-child(2) {
          input {
            min-width: 200px;
          }
        }

        &:nth-child(3) {
          input {
            min-width: 185px;
          }
        }
      }

      .step__field {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        label {
          position: absolute;
        }

        input {
          min-width: 140px;
        }
      }
    }
  }

  .step-failed,
  .step-success {
    display: none;
  }

  .step-failed {
    flex-direction: column;

    .step__line {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100px;
        margin-bottom: 55px;
      }

      h2,
      p {
        max-width: 355px;
      }
    }
  }

  .step-success {
    justify-content: center;

    .step__line {
      min-height: 600px;
      justify-content: center;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      h2 {
        max-width: 333px;
        margin-bottom: 109px;
      }

      img {
        max-width: 150px;
        margin-bottom: 109px;
      }

      p {
        a {
          color: $green;
        }
      }
    }
  }

  .step.active {
    display: block;
    opacity: 1;
  }

  .step.fade-out {
    opacity: 0;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 82px;

    right: 15px;
  }
}

.button-load-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 90px;

  button {
    background-color: #010101 !important;
    width: 133px !important;
    height: 32px !important;
    max-height: 32px !important;
    padding: 2px 0 0 0 !important;
    margin: 0 !important;
    border: none;
    color: #fff;
    border-radius: 8px !important;
    font-family: var(--fontText);
    cursor: pointer;
  }
}

@media (max-width: 978px) {
  .banner-form {
    .select2 {
      max-width: 100% !important;
    }

    padding: 90px 0 40px;

    .content-description-images {
      img {
        height: 14px;
      }
    }

    .form-description {
      .step__buttons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        input{
          min-width: 210px !important;
        }
      }

      .step {
        .step__line {
          select {
            font-size: 12.5px;
            text-indent: 3px;
          }
        }
      }
    }
  }
}