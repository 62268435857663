.pagaleve-news {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0;
    padding: 120px 0 120px;
    position: relative;
    background-color: #F9FAFB;
}

.pagaleve-news h2 {
    font-size: 48px;
    max-width: 603px;
}

.main-blog-page {
    a {
        text-decoration: none;
    }
}

.grid-news {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-top: 95px;
    max-width: 1020px;
    gap: 10px;
}

.grid div {
    position: relative;
}

.grid div img {
    width: 100%;
}

.grid div img.arrow-image {
    width: 14px;
}

.label,
.img-text,
.link-action {
    position: absolute;
}

.label {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    border-radius: 40px;
    background: #12f2aa;
    padding: 7px 14px;
    top: 30px;
    right: 40px;
}

.img-text {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.992px;
    text-transform: uppercase;
    bottom: 100px;
    left: 30px;
    max-width: 180px;
}

.link-action {
    color: #12f2aa;
    font-size: 14px;
    font-weight: 700;
    line-height: 28.992px;
    text-transform: uppercase;
    text-decoration: none;
    bottom: 40px;
    left: 30px;
    max-width: 180px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.post {
    width: 344px;
    height: 354px;
    overflow: hidden;
    border-radius: 32px;
    position: relative;

    &::before {
        background: rgba(0, 0, 0, .5);
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
    }

    .label {
        color: #000;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        border-radius: 40px;
        background: #12f2aa;
        padding: 7px 14px;
        top: 30px;
        right: 40px;
    }

    .img-text {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.992px;
        text-transform: uppercase;
        bottom: 80px;
        left: 20px;
        max-width: 305px;
    }

    .link-action {
        color: #12f2aa;
        font-size: 14px;
        font-weight: 700;
        line-height: 28.992px;
        text-transform: uppercase;
        text-decoration: none;
        bottom: 40px;
        left: 30px;
        max-width: 180px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    img {
        max-width: 100%;
        object-fit: cover;
    }
    
}

@media(max-width:1024px) {
    .pagaleve-news {
        padding: 43px 27px;

        h2 {
            font-size: 28px !important;
            line-height: 130%;
            font-weight: 600;
        }

        .grid-news {
            display: block !important;

            a {
                max-width: 344px;
                max-height: 362px;
                margin-right: 16px;
            }
        }

        .d-mobile {
            display: block;
        }

        .d-desk {
            display: none;
        }

        .slide-navigation-blog {
            margin-top: 60px;
        }
    }

}

@media(min-width:1024px) {
    .pagaleve-news {

        .d-mobile {
            display: none;
        }

        .d-desk {
            display: block;
        }
        .slide-navigation-blog {
            display: none;
        }
    }

}

@media(max-width:978px) {
    // .grid-news{
    //     display: flex !important;
    //     flex-direction: column !important;
    //     align-items: center;
    // }
}