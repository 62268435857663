body {
  background: var(--background) !important;
}

/* ESTILIZAÇÃO DE TODAS TAGS H1,P e BUTTON DA PÁGINA */
.who-page {
  overflow-x: hidden;
}

.who-page {
  h1 {
    color: var(--gray4);
    font-family: var(--font-text);
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
  }
}

.who-page {
  p {
    color: var(--dark1);
    font-family: var(--font-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}

.load-more {
  border-radius: 30px;
  border: 1px solid #da38eb;
  background: #da38eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 20px;
  width: 100%;
  max-width: 322px;
  text-transform: uppercase;
  margin: 0 auto;
}

/* FIM TODAS H1 DA PÁGINA */

/* INICIO BLOCO QUEM SOMOS */

.who-page {
  .who-are-we {
    margin-top: 44px;
    padding: 0 42px;
    display: flex;
    flex-direction: column;
  }
}

.who-are-we {
  .who-are-we__description {
    height: 480px;
    position: relative;
    overflow: hidden;
    transition: height 1s;
    max-width: 600px;
  }
}

.who-are-we {
  .who-are-we__description {
    .follow-up__row {
      .overlay {
        position: absolute;
        inset: 0;
        z-index: 2;
        // background-image: linear-gradient(rgba(249, 250, 251, 0) 80%,
        //     rgba(249, 250, 251, 1) 100%);
      }
    }
  }
}

.who-are-we {
  .who-are-we__description {
    .overlay {
      position: absolute;
      inset: 0;
      z-index: 2;
      // background-image: linear-gradient(rgba(249, 250, 251, 0) 80%,
      //     rgba(249, 250, 251, 1) 100%);
    }
  }
}

.who-page {
  .who-are-we {
    h1 {
      margin-bottom: 16px;
    }
  }
}

.who-are-we {
  .who-are-we__description {
    p {
      margin-bottom: 32px;
    }
  }
}

.who-are-we {
  .who-are-we__description {
    p:last-child {
      margin-bottom: 0px;
    }
  }
}

/* FIM BLOCO QUEM SOMOS */

/* INICIO BLOCO MISSAO E PROPOSITO */

.who-page {
  .mission-and-purpose {
    background: url("../../src/images/image-bg-green-arrow.png"),
      url("../../src/images/image-bg-green-arrow.png"),
      url("../../src/images/background/mobile-who-are.png");
    background-size: 200px, 200px, 100% 94%;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: -120px -50px, -120px 105%, 0px 5px;
    padding-top: 5px;
  }
}

.who-page {
  .mission-and-purpose {
    .arrow-layer {
      display: none;
    }
  }
}

.who-page {
  .mission-and-purpose {
    h1 {
      max-width: 162px;
    }
  }
}

.who-page {
  .mission-and-purpose {
    h1 {
      color: white;
    }
  }
}

.who-page {
  .mission-and-purpose {
    p {
      color: white;
    }
  }
}

.mission-and-purpose {
  .company-values {
    padding: 0 27px;
  }
}

.who-page {
  .mission-and-purpose {
    p {
      margin-top: 16px;
      font-weight: 500;
    }
  }
}

.who-page {
  .mission-and-purpose {
    .mission {
      padding-top: 160px;
      margin-bottom: 90px;

      &:nth-child(2) {
        margin: 0;
        padding-bottom: 235px;
        padding-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.who-page {
  .mission-and-purpose {
    .purpose {
      padding-bottom: 235px;
    }
  }
}

/* FIM BLOCO VALORES E PROPOSITO */

/* INICIO BLOCO ACOMPANHE... */

.who-page {
  .follow-up {
    padding: 0 27px;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
  }
}

.who-page {
  .follow-up {
    .follow-slide-items {
      margin-top: 80px;
    }
  }
}

.who-page {
  .follow-up-slide {
    .follow-slide-items {
      margin-top: 80px;
    }
  }
}

.who-page {
  .follow-up {
    h1 {
      font-size: 36px;
    }
  }
}

.who-page {
  .follow-up {
    .follow-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 50px;
    }
  }
}

.who-page {
  .follow-up-slide {
    .follow-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-bottom: 50px;
    }
  }
}

.who-page {
  .follow-up {
    .follow-item img {
      width: 48px;
    }
  }
}

.who-page {
  .follow-up-slide {
    .follow-item {
      img {
        width: 92px;
      }
    }
  }
}

.follow-item {
  .follow-image{
    min-height: 89px;
  }
  .follow-description {
    display: flex;
    flex-direction: column;
  }
}

.follow-item {
  .follow-description {
    .item-title {
      color: #000;
      font-family: var(--font-public);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }
  }
}

.follow-item {
  .follow-description {
    .item-description {
      color: var(--gray3);
      font-family: var(--font-public);
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
    }
  }
}

.follow-item {
  text-decoration: none;
  button {
    border-radius: 30px;
    border: 2px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #344054;
    font-family: var(--font-inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 20px;
    max-width: 124px;
    white-space: nowrap;
  }
}

/* FIM BLOCO ACOMPANHE.... */

@media (min-width: 645px) {
  .who-are-we {
    .who-are-we__description {
      height: 280px;
    }
  }
}

@media (min-width: 768px) {
  .who-page {
    .follow-up {
      margin-top: -10px;
    }
  }

  .who-page {
    .follow-up-slide {
      .follow-up-itens {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
}

@media (min-width: 1024px) {
  body {
    background: white !important;
  }

  /* INICIO BLOCO QUEM SOMOS */

  .who-page {
    h1 {
      font-size: 48px;
    }
  }

  .who-page {
    .who-are-we {
      h1 {
        margin-bottom: 72px;
      }
    }
  }
  .who-are-we {
    .who-are-we__description {
      height: auto;
    }
  }

  .who-are-we {
    .who-are-we__description {
      .overlay {
        display: none;
      }
    }
  }

  .who-are-we button {
    display: none;
  }

  /* FIM BLOCO QUEM SOMOS */

  /* INICIO BLOCO MISSAO */

  .who-page {
    .mission-and-purpose {
      background: #24359c;
      padding-top: 5px;
      margin-top: 44px;
      grid-column: 1/4;

      display: grid;
      grid-template-columns: 1fr 900px 1fr;
      position: relative;
    }
  }

  .who-page {
    .mission-and-purpose {
      h1 {
        max-width: initial;
      }
    }
  }

  .who-page {
    .mission-and-purpose {
      .arrow-layer {
        display: block;
        position: absolute;
        top: -220px;
        right: -30px;
        width: 400px;
      }
    }
  }

  .who-page {
    .mission-and-purpose {
      .company-values {
        grid-column: 2/3;
        padding: 0 43px;
        max-width: 600px;
      }
    }
  }

  .who-page {
    .mission-and-purpose {
      .mission {
        padding-top: 136px;
        margin-bottom: 49px;

        &:nth-child(2) {
          margin: 0;
          padding-bottom: 136px;
          padding-top: 0;
        }
      }
    }
  }

  .who-page {
    .mission-and-purpose {
      .purpose {
        padding-bottom: 136px;
      }
    }
  }
  /* FIM BLOCO MISSAO */

  /* INICIO BLOCO SLIDE ACOMPANHE */

  .who-page {
    .follow-up-slide {
      display: block;
      grid-column: 2;
      margin-top: 144px;

      .follow-up__row {
        .follow-up__itens {
          height: 600px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          position: relative !important;
        }
        button {
          z-index: 3;
          @media (min-width: 978px) {
            display: none !important;
          }
        }
      }
    }
  }

  .who-page {
    .follow-up-slide {
      h1 {
        max-width: 700px;
        margin-bottom: 75px;
      }
    }
  }

  .who-page {
    .follow-up {
      display: none;
    }
  }

  .who-page {
    .follow-up-slide {
      .follow-item {
        max-width: 214px;
        margin-right: 21px;
      }
    }
  }

  .who-page {
    .follow-up-slide {
      .follow-item {
        button {
          display: none;
        }
      }
    }
  }

  .follow-up-slide {
    .append-arrows {
      .arrow {
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }

  .follow-up-slide {
    .append-arrows {
      .arrow.disabled {
        cursor: initial;
      }
    }
  }

  .arrow.disabled {
    svg path {
      stroke: #cccdce;
    }
  }

  .prevArrow {
    transform: rotateY(180deg);
  }

  /* FIM BLOCO ACOMPANHE */
}

@media (min-width: 1280px) {
  /* INICIO BLOCO QUEM SOMOS */

  .who-are-we {
    .who-are-we__description {
      max-width: 600px !important;
    }
  }

  .who-page {
    .who-are-we {
      grid-column: 2/3;
      padding-top: 5px;
      margin-top: 44px;
      display: grid;
      grid-template-columns: 1fr 900px 1fr;
      position: relative;
    }
  }

  /* FIM BLOCO QUEM SOMOS */
}

@media (min-width: 1440px) {
  /* INICIO BLOCO QUEM SOMOS */

  .who-page {
    .mission-and-purpose {
      .company-values {
        max-width: 700px;
      }
    }
  }
}

@media (max-width: 978px) {
  .follow-up-slide {
    padding: 0 27px;
    h1 {
      margin-bottom: 40px;
    }
    .follow-up__row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .follow-up__itens {
        height: 680px;
        overflow: hidden;
        padding-bottom: 40px;
      }
      button {
        z-index: 3;
      }
    }
  }
}
