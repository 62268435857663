.pagaleve-payments {
  padding: 0 43px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: none;
  }
}

.pagaleve-payments h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  max-width: 200px;
}

.pagaleve-payments h3 {
  margin: 16px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}

.pagaleve-payments .blackphone {
  position: relative;
  margin-top: 60px;
  width: 440px;
  height: 360px;
  right: 136px;
}

.pagaleve-payments .greenphones {
  width: 400px;
  height: 380px;
  position: relative;
  right: 53px;
}

.tutorial {
  position: relative;
  margin: 150px 0 0;
  overflow: initial;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-position: -90px;
  background-size: 230% 100%;
}

.tutorial__smartphones {
  position: absolute;
  width: 330px;
  height: 320px;
  align-self: flex-start;
  top: -150px;
}

.tutorial__content {
  margin-top: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 43px;
}

.content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tutorial__content h2 {
  margin-top: 15px;
  max-width: 135px;
}

.tutorial__content h3 {
  padding: 15px 0;
  margin-top: 15px;
  max-width: 135px;
  text-align: center;
}

.tutorial__content .no-tax {
  margin-top: 76px;

  @media (min-width: 1024px) {
    margin-top: 0;
  }
}

.tutorial__content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 43px;
}

.tutorial__title-1 {
  align-self: flex-start;
}

.tutorial.green-phones {
  position: relative;
  bottom: 40px;
}

.tutorial-carousel {
  display: none;
}

.pageleve-numbers {
  display: flex;
  flex-direction: column;
  padding: 0 43px;
  margin-top: 46px;
}

.pagaleve-numbers .desk-title {
  display: none;
}

.pageleve-numbers h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
}

.pageleve-numbers p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}

.pagaleve-number__results {
  display: grid;
  row-gap: 60px;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  margin-top: 46px;
}

.pagaleve-number__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    max-height: 230px;
  }
  .image {
    max-width: 98px;
    min-height: 97px;
  }
  .title {
    color: #da38eb;
    font-weight: 700;
    font-size: 46px;
    line-height: 51px;
    padding: 0px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tag {
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    font-family: $fontText;
    color: #da38eb;
  }
  .describe {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-top: 30px;
    min-height: 108px;
  }
}
@media (max-width: 978px) {
  .pagaleve-payments {
    padding: 60px 20px;
    justify-content: center;
    align-items: flex-start;
    img {
      max-width: 100%;
      height: auto !important;
      margin: 20px 0;
      right: unset !important;
      left: unset !important;
    }
  }
  .pagaleve-number__results {
    grid-template-columns: 1fr;
  }
  .pagaleve-number__item {
    .describe {
      min-height: unset !important;
    }
  }
}
